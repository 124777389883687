const API_STRINGS = {
  APP_MANAGER: {
    GET_LIST: 'api/apps',
    GET_DETAIL: 'api/apps',
    PUBLISH_APP: 'api/apps/publish',
    UNPUBLISH_APP: 'api/apps/unpublish',
    CREATE_APP: 'api/apps',
    EDIT_APP: 'api/apps/edit',
    GET_CATEGORIES: 'api/apps/app-categories',
    TOGGLE_SHOW_REVIEWS: 'api/AppManager/ToggleShowReviews',
    GET_BAU_MODULES: 'api/apps/mobile-bau-modules',
  },
  RATING_MANAGER: {
    GET_LIST: 'api/ratings',
    TOGGLE_COMMENT_VISIBILITY: (id) =>
      `api/ratings/${id}/ToggleCommentVisibility`,
  },
};

export default API_STRINGS;
