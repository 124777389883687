import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { deleteAppPackageQuery } from '../apis';
import { toast } from 'react-toastify';

const useDeleteAppPackage = () => {
  const mutationAction = useMutation({
    mutationFn: deleteAppPackageQuery,
    onError: (error) => {
      const message = error?.response?.data?.message ?? 'Server error';
      toast.error(message);
      console.error('Error delete app package:', error);
    },
  });

  return mutationAction;
};

export default useDeleteAppPackage;
