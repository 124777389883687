import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { removeItem } from '..';
import { deleteClientsByAppInstance } from '../apis';

const useDeleteClientByAppInstance = () => {
  const mutationAction = useMutation({
    mutationFn: deleteClientsByAppInstance,
    onSuccess: (data) => {
      toast.success('Delete Client successfully');
    },
    onError: (error) => {
      const message = error?.response?.data?.message ?? 'Server error';
      toast.error(message);
      console.error('Error delete Client:', error);
    },
  });

  return mutationAction;
};

export default useDeleteClientByAppInstance;
