import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { startContainerApp } from '../apis';
import { addItem } from '..';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const useStartAppContainer = () => {
  const dispatch = useDispatch();

  const mutationData = useMutation({
    mutationFn: startContainerApp,
    onSuccess: (data) => {
      toast.success('Run start app container successfully');
    },
    onError: (error) => {
      const message = error?.response?.data?.message ?? 'Server error';
      toast.error(message);
      console.error('Error run start app container:', error);
    },
  });

  return mutationData;
};

export default useStartAppContainer;
