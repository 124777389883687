import React from 'react';
import AppsContainer from '@tenant/core/AppsContainer';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import clsx from 'clsx';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ROUTE_STRING } from 'constants/RouteString';
import DockerImageTable from './components/List/DockerImageTable';
import Add from './Add';
import Detail from './Detail';
import UpgradeVersion from './UpgradeVersion';
import ViewLog from './components/Detail/ViewLog';

const ListDockerImage = () => {
  const location = useLocation();
  const { repository } = useParams();
  const isAddRoute = location.pathname === ROUTE_STRING.DOCKER_IMAGE.ADD;
  const isView = !!repository;
  const isUpgradeVersion =
    ROUTE_STRING.DOCKER_IMAGE.UPGRADE_VERSION.replace(
      ':repository',
      repository,
    ) === location.pathname;
  const isLog =
    ROUTE_STRING.DOCKER_IMAGE.LOG.replace(':repository', repository) ===
    location.pathname;

  return (
    <AppsContainer
      fullView
      title={
        <Breadcrumbs aria-label='breadcrumb'>
          <Typography color='text.primary'>Library</Typography>
          <Typography color='text.primary'>
            <Link to={ROUTE_STRING.DOCKER_IMAGE.LIST}>Docker Repositories</Link>
          </Typography>
          {isAddRoute && <Typography color='text.primary'>Add</Typography>}
        </Breadcrumbs>
      }
    >
      <Box
        sx={{
          visibility: !isAddRoute && !isView ? 'visible' : 'hidden',
          '& .MuiDataGrid-root': {
            borderLeft: 0,
            borderRight: 0,
            borderTop: 0,
            borderRadius: 0,
          },
        }}
      >
        <DockerImageTable />
      </Box>

      <Box
        sx={{
          transition: 'all 0.5s ease',
          transform: 'translateX(100%)',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
          opacity: 0,
          visibility: 'hidden',
          backgroundColor: 'background.paper',
          '&.show': {
            transform: 'translateX(0)',
            opacity: 1,
            visibility: 'visible',
          },
        }}
        className={clsx({
          show: isAddRoute || isView,
        })}
      >
        {!isView ? (
          <Add />
        ) : isUpgradeVersion ? (
          <UpgradeVersion />
        ) : isLog ? (
          <ViewLog />
        ) : (
          <Detail />
        )}
      </Box>
    </AppsContainer>
  );
};

export default React.memo(ListDockerImage);
