import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getDetailDockerImageQuery } from '../apis';
import queryKeyDockerImage from '../constants/queryKeyDockerImage';

const useGetDetailDockerImage = (repository) => {
  const getData = useQuery({
    queryKey: queryKeyDockerImage.getDetail(repository),
    queryFn: () => getDetailDockerImageQuery(repository),
  });

  return getData;
};

export default useGetDetailDockerImage;
