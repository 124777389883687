import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import useModal from '@tenant/utility/hooks/useModal';
import DeleteDialog from '@tenant/core/App/Dialog/DeleteDialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { unpublishApp } from 'api/appManager';
import { LIST_APP_DETAIL_QUERY } from 'constants/QueryString';
import { toast } from 'react-toastify';

const UnpublishAppAction = ({ appId, appName }) => {
  const queryClient = useQueryClient();
  const { visible, onClose, onShow } = useModal();

  const mutateQuery = useMutation({
    mutationFn: unpublishApp,
    onSuccess: () => {
      toast.success('Unpublish app successfully');
      queryClient.invalidateQueries([LIST_APP_DETAIL_QUERY, appId]);
      onClose();
    },
    onError: (error) => {
      const message =
        error?.response?.data?.message ?? 'Unpublished app failed';
      toast.error(message);
      console.error('Error unpublish app:', error);
    },
  });

  const handleSubmit = () => {
    mutateQuery.mutate({ appId });
  };

  return (
    <>
      <Button color='error' onClick={onShow}>
        Unpublish App
      </Button>
      <DeleteDialog
        visible={visible}
        loading={mutateQuery.isPending}
        name={appName}
        action='Unpublish'
        handleClose={onClose}
        handleSave={handleSubmit}
      />
    </>
  );
};

export default React.memo(UnpublishAppAction);

UnpublishAppAction.propTypes = {
  appId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  appName: PropTypes.string,
};
