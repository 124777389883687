import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Tab,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppTooltip from '@tenant/core/AppTooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IntlMessages from '@tenant/utility/IntlMessages';
import AppsContent from '@tenant/core/AppsContainer/AppsContent';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import useAppDetailQuery from '../hooks/useAppDetailQuery';
import { ROUTE_STRING } from 'constants/RouteString';
import { TAB_OVERVIEW, TAB_RATING } from '../helpers/constants';
import { DATE_TIME_FORMAT } from 'constants/Common';
import moment from 'moment';
import TabReview from './TabReview';
import { StatusApp } from './Table';
import PublishApp from './PublishApp';
import { StatusAppType } from 'constants/AppEnums';
import UnpublishAppAction from './UnpublishApp/UnpublishAppAction';

const Detail = () => {
  const { appId } = useParams();
  const { data, isLoading } = useAppDetailQuery({ appId });
  const navigate = useNavigate();
  const [value, setValue] = React.useState(TAB_OVERVIEW);

  const onClickBackButton = () => {
    navigate(ROUTE_STRING.LIST_APP.LIST);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = () => {
    navigate(ROUTE_STRING.LIST_APP.LIST + '/' + appId + '/edit');
  };

  useEffect(() => {
    setValue(TAB_OVERVIEW);
  }, [data]);

  return (
    <>
      <AppsHeader>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              gap: 4,
            }}
            component='span'
            mr={{ xs: 2, sm: 4 }}
          >
            <AppTooltip title={<IntlMessages id='common.back' />}>
              <ArrowBackIcon
                sx={{
                  color: 'text.secondary',
                }}
                onClick={onClickBackButton}
              />
            </AppTooltip>
            <Chip
              label={StatusApp[data?.Status]?.label}
              color={StatusApp[data?.Status]?.color}
              icon={StatusApp[data?.Status]?.icon}
              sx={{ px: 3, width: 150 }}
            />
          </Box>
          <Box sx={{ '& .MuiButton-root': { ml: 1, mr: 1 } }}>
            <Button onClick={handleEdit}>Edit</Button>
            {data?.Status === StatusAppType.DRAFT && <PublishApp data={data} />}
            {data?.Status === StatusAppType.ACTIVE && !!data?.Id && (
              <UnpublishAppAction appId={data?.Id} appName={data?.Name} />
            )}
          </Box>
        </Box>
      </AppsHeader>

      <AppsContent isDetailView>
        <Box p={4}>
          {isLoading ? (
            <Stack direction='column' spacing={2}>
              <Stack direction='row' spacing={4}>
                <Skeleton variant='circular' width={120} height={120} />
                <Stack direction='column' spacing={2} sx={{ width: '100%' }}>
                  <Skeleton
                    variant='rounded'
                    height={20}
                    sx={{ width: '100%' }}
                  />
                  <Skeleton
                    variant='rounded'
                    height={80}
                    sx={{ width: '100%' }}
                  />
                </Stack>
              </Stack>
              <Stack direction='row' spacing={2}>
                <Skeleton variant='rounded' width={200} height={48} />
                <Skeleton variant='rounded' width={200} height={48} />
              </Stack>
              <Skeleton variant='rounded' height={60} sx={{ width: '100%' }} />
              <Skeleton variant='rounded' height={60} sx={{ width: '100%' }} />
            </Stack>
          ) : (
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  sx={{
                    '& .img-col': { minWidth: 140 },
                    '& .content-col': {
                      width: 'calc(100% - 140px)',
                    },
                  }}
                >
                  <Box className='img-col'>
                    <img
                      src={data?.IconBlobReference}
                      alt='logo'
                      style={{
                        height: 120,
                        width: 120,
                        borderRadius: '1rem',
                        p: 1,
                      }}
                    />
                  </Box>
                  <Box className='content-col'>
                    <Typography variant='h4' mb={2}>
                      {data?.Name}
                    </Typography>
                    <Typography variant='caption'>
                      By Joblogic Marketplace
                    </Typography>
                    <Tooltip title={data?.ShortDescription}>
                      <Typography
                        mb={1}
                        variant='caption'
                        component='p'
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {data?.ShortDescription}
                      </Typography>
                    </Tooltip>
                    {!!data?.KeyLabels && (
                      <Chip
                        size='small'
                        label={data?.KeyLabels}
                        color='success'
                        variant='outlined'
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box sx={{ width: '100%', typography: 'body1', mt: 2 }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label='lab API tabs example'
                      >
                        <Tab label='Overview' value='1' />
                        <Tab label='Ratings & Reviews' value='2' />
                      </TabList>
                    </Box>
                    <TabPanel value={TAB_OVERVIEW}>
                      <Grid container>
                        <Grid item xs={12} sm={9} sx={{ p: 4 }}>
                          <Typography
                            variant='body2'
                            component='div'
                            dangerouslySetInnerHTML={{
                              __html: data?.LongDescription,
                            }}
                          />
                          <Typography variant='h2' sx={{ my: 4 }}>
                            Key Benefits:
                          </Typography>
                          <Typography
                            variant='body2'
                            component='div'
                            dangerouslySetInnerHTML={{
                              __html: data?.KeyBenefits,
                            }}
                          />
                        </Grid>
                        <Divider
                          orientation='vertical'
                          flexItem
                          sx={{ mr: '-1px' }}
                        />
                        <Grid item xs={12} sm={3}>
                          {data?.Status !== StatusAppType.DRAFT && (
                            <>
                              {data?.AppVersion && (
                                <ListItem>
                                  <ListItemText
                                    primary='Version'
                                    secondary={data?.AppVersion}
                                  />
                                </ListItem>
                              )}

                              {data?.MobileAppVersion && (
                                <ListItem>
                                  <ListItemText
                                    primary='Mobile App Version'
                                    secondary={data?.MobileAppVersion}
                                  />
                                </ListItem>
                              )}

                              <ListItem>
                                <ListItemText
                                  primary='Last updated'
                                  secondary={
                                    data?.LastUpdated
                                      ? moment(data?.LastUpdated).format(
                                          DATE_TIME_FORMAT,
                                        )
                                      : null
                                  }
                                />
                              </ListItem>
                            </>
                          )}
                          <ListItem>
                            <ListItemText
                              primary='Categories'
                              secondary={[
                                ...new Map(
                                  (data?.Categories ?? []).map((item) => [
                                    item.Id,
                                    item,
                                  ]),
                                ).values(),
                              ]
                                .map((item) => item.Name)
                                .join('&')}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary='Dependencies'
                              secondary='Core Joblogic System'
                            />
                          </ListItem>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel
                      value={TAB_RATING}
                      sx={{
                        '& svg': { fill: '#faaf00', ml: 1 },
                        '& .progress': { width: 300, ml: 5 },
                        '& .number': {
                          width: 10,
                        },
                        '& .star': {
                          display: 'flex',
                          alignItems: 'center',
                          width: '30px',
                        },
                        '& .row-star': {
                          display: 'flex',
                          alignItems: 'center',
                          margin: '5px 0',
                        },
                      }}
                    >
                      <TabReview />
                    </TabPanel>
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </AppsContent>
    </>
  );
};

export default Detail;
