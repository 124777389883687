import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CronModal from '@tenant/core/App/Cron/CronModal';
import useModal from '@tenant/utility/hooks/useModal';
import useUpdateCron from 'redux/slices/containerApp/hooks/useUpdateCron';
import { LoadingButton } from '@mui/lab';
import { cronRegex } from 'redux/slices/appInstance/constants/validationSchema';

const validationSchema = yup.object({
  Cron: yup.string().required().label('Cron'),
});

const UpdateCronDialog = ({
  visible,
  handleClose,
  initialValues,
  appInstanceId,
  containerAppId,
}) => {
  const {
    visible: visibleCron,
    onClose: onCloseCron,
    onShow: onShowCron,
  } = useModal();

  const updateCronMutate = useUpdateCron();

  const checkIsValidateCronRegex = (cronValue) => {
    return cronRegex.test(cronValue);
  };

  return (
    <Dialog open={visible} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle fontSize={16}>Edit Cron</DialogTitle>

      <Formik
        validateOnChange
        validateOnBlur
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          updateCronMutate.mutate(
            {
              appInstanceId,
              containerAppId,
              Cron: data.Cron,
            },
            {
              onSuccess() {
                handleClose();
              },
            },
          );
        }}
        enableReinitialize
      >
        {({ values, setFieldValue, submitForm, errors }) => (
          <>
            <DialogContent>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    value={values.Cron ?? ''}
                    error={!!errors.Cron}
                    helperText={errors.Cron}
                    onChange={(e) => setFieldValue('Cron', e.target.value)}
                  />

                  <Typography mt={2} variant='body2'>
                    Format: {'{minute} {hour} {day} {month} {day-of-week}'}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <LoadingButton
                loading={updateCronMutate?.isPending}
                onClick={submitForm}
                variant='contained'
              >
                Save
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default React.memo(UpdateCronDialog);

UpdateCronDialog.propTypes = {};
