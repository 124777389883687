import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addAppInstanceQuery } from '../apis';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import queryKeyAppInstance from '../constants/queryKeyAppInstance';
import { addItemAppInstance } from '..';

const useAddAppInstance = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { filter } = useSelector((s) => s.appInstance);

  const addAppInstance = useMutation({
    mutationFn: addAppInstanceQuery,
    onSuccess: (result) => {
      toast.success('Add App Instance successfully');
      dispatch(addItemAppInstance(result?.data));
      queryClient.invalidateQueries([
        queryKeyAppInstance.getList(JSON.stringify(filter ?? {})),
      ]);
    },
    onError: (error) => {
      const message = error?.response?.data?.message ?? 'Server error';
      toast.error(message);
      console.error('Error add app package:', error);
    },
  });

  return addAppInstance;
};

export default useAddAppInstance;
