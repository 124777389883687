import React, { useState } from 'react';
import useGetListLogContainerApp from 'redux/slices/containerApp/hooks/useGetListLogContainerApp';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';
import {
  inputFormat,
  inputFormatDateTime,
} from '@tenant/utility/constants/default';
import { DateMenu } from '@tenant/core/App/Date/DateMenu';
import { getRangeDateByKeyName } from '@tenant/utility/helper/Utils';
import useNextTick from '@tenant/utility/hooks/useNextTick';
import moment from 'moment';
import { STATISTIC_DATE_GROUP_TYPE } from '@tenant/utility/constants/enum';
import LogList from 'pages/containerApp/Common/LogList';

const defaultRangeName = 'Last 1 day';
const dataOptions = {
  'Last 1 day': {
    value: [
      moment().subtract(1, 'days').format(inputFormat),
      moment().format(inputFormat),
    ],
    description: `${moment().subtract(1, 'days').format('MMM')} ${moment()
      .subtract(1, 'days')
      .format('DD')}-${moment().format('MMM')} ${moment().format('DD')}`,
    key: 'days',
    groupType: STATISTIC_DATE_GROUP_TYPE.Day,
    title: 'Relative dates',
    text: 'Last 1 day',
  },
  'Last 3 days': {
    value: [
      moment().subtract(3, 'days').format(inputFormat),
      moment().format(inputFormat),
    ],
    description: `${moment().subtract(3, 'days').format('MMM')} ${moment()
      .subtract(3, 'days')
      .format('DD')}-${moment().format('MMM')} ${moment().format('DD')}`,
    key: 'days',
    groupType: STATISTIC_DATE_GROUP_TYPE.Day,
    title: 'Relative dates',
    text: 'Last 3 days',
  },
  'Last 7 days': {
    value: [
      moment().subtract(7, 'days').format(inputFormat),
      moment().format(inputFormat),
    ],
    description: `${moment().subtract(7, 'days').format('MMM')} ${moment()
      .subtract(7, 'days')
      .format('DD')}-${moment().format('MMM')} ${moment().format('DD')}`,
    key: 'days',
    groupType: STATISTIC_DATE_GROUP_TYPE.Day,
    title: 'Relative dates',
    text: 'Last 7 days',
  },
  'Last 30 days': {
    value: [
      moment().subtract(30, 'days').format(inputFormat),
      moment().format(inputFormat),
    ],
    description: `${moment().subtract(30, 'days').format('MMM')} ${moment()
      .subtract(30, 'days')
      .format('DD')}-${moment().format('MMM')} ${moment().format('DD')}`,
    key: 'days',
    groupType: STATISTIC_DATE_GROUP_TYPE.Day,
    title: 'Relative dates',
    text: 'Last 30 days',
  },
};
const { start, end } = getRangeDateByKeyName(defaultRangeName, dataOptions);

const Logs = () => {
  const nextTick = useNextTick();
  const { log, logFilter } = useSelector((s) => s.containerApp);
  const [rangeDate, setRangeDate] = useState(defaultRangeName);

  const [filter, setFilter] = useState(logFilter ? { ...logFilter } : {});
  const { appInstanceId, id: containerAppId } = useParams();
  const { isLoading, isRefetching, refetch } = useGetListLogContainerApp({
    startDate: start,
    endDate: end,
    ...filter,
    appInstanceId,
    containerAppId,
  });

  const columns = [
    {
      field: 'CreatedDate',
      headerName: 'Date',
      width: 175,
      sortable: true,
      renderCell: (params) =>
        params.value
          ? formatDateFromISO(params.value, inputFormatDateTime)
          : '',
    },
    {
      field: 'ContainerAppName',
      headerName: 'Name',
      width: 200,
      sortable: true,
    },
    {
      field: 'AppLog',
      headerName: 'App Log',
      width: 400,
      flex: 1,
      sortable: false,
      // renderCell: (params) => <Box> {params.value} </Box>,
    },
  ];

  const handleChangeDate = async (data) => {
    const { keyName, startDate, endDate } = data || {};
    setRangeDate(keyName);
    setFilter({
      startDate,
      endDate,
    });
    await nextTick();
    refetch();
  };

  return (
    <>
      <Box display={'flex'} justifyContent={'end'}>
        <DateMenu
          defaultValue={rangeDate}
          handleChangeValue={(data) => handleChangeDate(data)}
          dataOptions={dataOptions}
          isCustomDateRange={false}
        />
      </Box>
      {/* <Box sx={{ height: 'calc(100vh - 275px)' }}>
        <DataGrid
          loading={isLoading || isRefetching}
          hideFooter
          disableColumnSelector
          disableColumnMenu
          rows={log?.Data ?? []}
          columns={columns}
          getRowId={(row) => row.CreatedDate}
          getRowHeight={() => 'auto'}
          sx={{
            '& [data-field="AppLog"] .MuiDataGrid-cellContent': {
              whiteSpace: 'normal',
              pt: 3,
              pb: 3,
            },
          }}
        />
      </Box> */}
      <LogList data={log?.Data ?? []} loading={isLoading || isRefetching} />
    </>
  );
};

export default React.memo(Logs);

Logs.propTypes = {};
