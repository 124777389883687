import React from 'react';
import PropTypes from 'prop-types';
import useDeleteDockerImage from 'redux/slices/dockerImage/hooks/useDeleteDockerImage';
import { useDispatch } from 'react-redux';
import { removeItem } from 'redux/slices/dockerImage';
import useModal from '@tenant/utility/hooks/useModal';
import { Box } from '@mui/material';
import DeleteDialog from '@tenant/core/App/Dialog/DeleteDialog';

const DeleteDockerImageAction = ({
  trigger,
  open,
  selected,
  handleCloseDelete = () => {},
  handleSubmitDelete = () => {},
}) => {
  const deleteMutate = useDeleteDockerImage();
  const dispatch = useDispatch();
  const { visible, onShow, onClose } = useModal();
  const visibleDelete = typeof open !== 'undefined' ? open : visible;

  const handleClose = () => {
    onClose();
    handleCloseDelete();
  };

  const handleDelete = () => {
    deleteMutate.mutate(selected, {
      onSuccess() {
        dispatch(removeItem(selected));
        onClose();
        handleSubmitDelete();
      },
    });
  };

  return (
    <>
      <Box component='span' onClick={onShow}>
        {trigger}
      </Box>
      <DeleteDialog
        visible={visibleDelete}
        loading={deleteMutate.isPending}
        name={selected?.Name}
        handleClose={handleClose}
        handleSave={handleDelete}
      />
    </>
  );
};

export default React.memo(DeleteDockerImageAction);

DeleteDockerImageAction.propTypes = {
  trigger: PropTypes.node,
  open: PropTypes.bool,
  selected: PropTypes.object,
  handleCloseDelete: PropTypes.func,
};
