import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import { LoadingButton } from '@mui/lab';
import DockerAppTypes from 'constants/DockerAppTypes';
import { grey } from '@mui/material/colors';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AppGlossary from '@tenant/core/App/Common/AppGlossary';
import useNextTick from '@tenant/utility/hooks/useNextTick';
import checkIsRequireTargetPort from 'redux/slices/dockerImage/helpers/checkIsRequireTargetPort';
import { useTheme } from '@emotion/react';
import useGetAvailableDockerImage from 'redux/slices/dockerImage/hooks/useGetAvailableDockerImage';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const AddDockerImageForm = ({
  onCancel = () => {},
  submitForm = () => {},
  setForm = () => {},
  validateField = () => {},
  values = {},
  errors = {},
  loading = false,
  isUpgrade = false,
}) => {
  const theme = useTheme();
  const nextTick = useNextTick();
  const {
    refetch,
    item: exists,
    loading: loadingAvailable,
    isLoaded,
  } = useGetAvailableDockerImage();

  const setFieldValue = async (field, value, isTrigger = true) => {
    setForm(field, value, false);
    if (isTrigger) {
      await nextTick();
      validateField(field);
    }
  };

  const handleBlurImageName = (e) => {
    e.target.value && refetch(e.target.value);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          [theme.breakpoints.up('xl')]: {
            height: 'calc(100vh - 285px)',
          },
          [theme.breakpoints.only('lg')]: {
            height: 'calc(100vh - 245px)',
          },
          [theme.breakpoints.only('md')]: {
            height: 'calc(100vh - 250px)',
          },
          [theme.breakpoints.only('sm')]: {
            height: 'calc(100vh - 233px)',
          },
          [theme.breakpoints.only('xs')]: {
            height: 'calc(100vh - 269px)',
          },
          overflow: 'auto',
          p: 4,
          ml: -4,
          mr: -4,
        }}
      >
        <Grid
          container
          spacing={5}
          sx={{ '& .Mui-disabled': { background: grey[50] } }}
        >
          <Grid item xs={12} sm={12}>
            <Typography variant='h3' mb={4}>
              Image Info
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box component='p' color='text.primary' fontSize={14} mb={2}>
              Image Name
              <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                *
              </Box>
            </Box>
            <TextField
              fullWidth
              value={values.ImageName ?? ''}
              error={(isLoaded && exists) || !!errors.ImageName}
              helperText={
                isLoaded && exists
                  ? 'Image Name is exists, try to change the other Image Name.'
                  : errors.ImageName
              }
              disabled={isUpgrade || loadingAvailable}
              placeholder='E.g. joblogicimage'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {loadingAvailable ? (
                      <CircularProgress size={16} />
                    ) : (
                      isLoaded &&
                      !exists &&
                      !errors.ImageName && <CheckCircleIcon color='success' />
                    )}
                  </InputAdornment>
                ),
              }}
              onChange={(e) =>
                !isUpgrade && setFieldValue('ImageName', e.target.value)
              }
              onBlur={(e) => !errors.ImageName && handleBlurImageName(e)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box component='p' color='text.primary' fontSize={14} mb={2}>
              Deployment Type
              <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                *
              </Box>
            </Box>
            <Autocomplete
              disablePortal
              disabled={isUpgrade}
              value={values.DeploymentType ?? null}
              options={DockerAppTypes}
              getOptionLabel={(row) => row.Description}
              isOptionEqualToValue={(option, value) => {
                return option?.Value === value?.Value;
              }}
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                  key={option.Value}
                >
                  {option.Description}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.DeploymentType}
                  helperText={errors.DeploymentType}
                />
              )}
              onChange={(e, value) => {
                if (isUpgrade) {
                  return;
                }
                setFieldValue('DeploymentType', value);
                if (value?.IsCron) {
                  setFieldValue('TargetPort', '');
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box component='p' color='text.primary' fontSize={14} mb={2}>
              Target Port
              {checkIsRequireTargetPort(values.DeploymentType) && (
                <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                  *
                </Box>
              )}
            </Box>
            <TextField
              type='number'
              disabled={!checkIsRequireTargetPort(values.DeploymentType)}
              fullWidth
              value={values.TargetPort ?? ''}
              placeholder='E.g. 80'
              error={
                checkIsRequireTargetPort(values.DeploymentType) &&
                !!errors.TargetPort
              }
              helperText={
                checkIsRequireTargetPort(values.DeploymentType) &&
                errors.TargetPort
              }
              onChange={(e) => setFieldValue('TargetPort', e.target.value)}
              sx={{ '& .Mui-disabled': { background: grey[50] } }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box component='p' color='text.primary' fontSize={14} mb={2}>
              Required Environment Variables
              <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                *
              </Box>
              <AppGlossary title='Please break down your input if you have multiple values.' />
            </Box>
            <TextField
              fullWidth
              multiline
              rows={5}
              value={values.RequiredEnviromentVariables ?? ''}
              error={!!errors.RequiredEnviromentVariables}
              helperText={errors.RequiredEnviromentVariables}
              placeholder='E.g. CLIENT_ID'
              onChange={(e) =>
                setFieldValue('RequiredEnviromentVariables', e.target.value)
              }
              sx={{ '& .Mui-disabled': { background: grey[50] } }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant='h3' mb={4} mt={4}>
              Image Source
            </Typography>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box component='p' color='text.primary' fontSize={14} mb={2}>
              Branch Name
              <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                *
              </Box>
            </Box>
            <TextField
              fullWidth
              value={values.BranchName ?? ''}
              error={!!errors.BranchName}
              helperText={errors.BranchName}
              placeholder='E.g. joblogicbranchname'
              onChange={(e) => setFieldValue('BranchName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box component='p' color='text.primary' fontSize={14} mb={2}>
              Repository Url
              <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                *
              </Box>
            </Box>
            <TextField
              fullWidth
              value={values.RepositoryUrl ?? ''}
              error={!!errors.RepositoryUrl}
              helperText={errors.RepositoryUrl}
              placeholder='E.g. https://joblogic.com/marketplace/_git/demo-app'
              onChange={(e) => setFieldValue('RepositoryUrl', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box component='p' color='text.primary' fontSize={14} mb={2}>
              Dockerfile Path
              <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                *
              </Box>
            </Box>
            <TextField
              fullWidth
              value={values.DockerfilePath ?? ''}
              error={!!errors.DockerfilePath}
              helperText={errors.DockerfilePath}
              placeholder='E.g. path/to/Dockerfile'
              onChange={(e) => setFieldValue('DockerfilePath', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box component='p' color='text.primary' fontSize={14} mb={2}>
              Access token
              <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                *
              </Box>
              <AppGlossary title='This is a Personal Access Token. These can be used instead of a password for applications like Git or can be passed in the authorization header to access REST APIs.' />
            </Box>
            <TextField
              fullWidth
              type={values.ShowPassword ? 'text' : 'password'}
              value={values.AccessToken ?? ''}
              error={!!errors.AccessToken}
              helperText={errors.AccessToken}
              onChange={(e) => setFieldValue('AccessToken', e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() =>
                        setFieldValue('ShowPassword', !values.ShowPassword)
                      }
                    >
                      {values.ShowPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <FooterWrapper
        className='footer'
        sx={{
          margin: '0px -20px 0px -20px',
          backgroundColor: '#f4f7fe',
          height: '50px',
        }}
      >
        <Stack
          direction='row'
          justifyContent='end'
          sx={{
            position: 'sticky',
            bottom: 0,
            height: '100%',
            p: 2,
          }}
        >
          <Button variant='outlined' onClick={onCancel} sx={{ mr: 3 }}>
            Cancel
          </Button>

          <LoadingButton
            variant='contained'
            loading={loading}
            sx={{ mr: 3 }}
            onClick={() => {
              !isUpgrade
                ? !exists && !loadingAvailable && submitForm()
                : submitForm();
            }}
          >
            Save
          </LoadingButton>
        </Stack>
      </FooterWrapper>
    </Box>
  );
};

export default React.memo(AddDockerImageForm);
