const reducer = {
  setFieldDockerImage(state, action) {
    const { field, value } = action.payload ?? {};
    if (field) {
      state[field] = value;
    }
  },
  addItem(state, action) {
    state.list.Items.push({
      ...action.payload,
      Name: action.payload?.ImageName,
    });
  },
  removeItem(state, action) {
    state.list.Items = state.list.Items.filter(
      (e) => e.Name !== action.payload.Name,
    );
  },
  viewItem(state, action) {
    state.viewItem = action.payload;
  },
};

export default reducer;
