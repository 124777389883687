import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  dividerClasses,
  Grid,
  IconButton,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppTooltip from '@tenant/core/AppTooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IntlMessages from '@tenant/utility/IntlMessages';
import { ROUTE_STRING } from 'constants/RouteString';
import DockerAppTypes from 'constants/DockerAppTypes';
import { grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DockerImageVersion from './components/List/DockerImageVersion';
import SimpleBar from 'simplebar-react';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDockerImageAction from './components/Common/DeleteDockerImageAction';
import useGetDetailDockerImage from 'redux/slices/dockerImage/hooks/useGetDetailDockerImage';
import { Code } from 'react-content-loader';
import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';
import { inputFormatDateTime } from '@tenant/utility/constants/default';
import Error404 from 'pages/errorPages/Error404';
import RefreshIcon from '@mui/icons-material/Refresh';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Detail = () => {
  const navigate = useNavigate();
  const { repository } = useParams();
  const { data, isLoading, isRefetching, isError, refetch } =
    useGetDetailDockerImage(repository);
  const item = data?.data || {};
  const lastRun = item.LastRun ?? {};
  const loading = isLoading || isRefetching;
  const isCronApp = DockerAppTypes.find(
    (e) => e.Value === lastRun.DeploymentType,
  )?.IsCron;

  const onClickBackButton = () => {
    navigate(ROUTE_STRING.DOCKER_IMAGE.LIST);
  };

  const goToUpgrade = () => {
    navigate(
      ROUTE_STRING.DOCKER_IMAGE.UPGRADE_VERSION.replace(
        ':repository',
        repository,
      ),
    );
  };

  if (isError) {
    return <Error404 />;
  }

  return (
    <>
      <AppsHeader>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              gap: 4,
            }}
            mr={{ xs: 2, sm: 4 }}
          >
            <AppTooltip title={<IntlMessages id='common.back' />}>
              <ArrowBackIcon
                sx={{
                  color: 'text.secondary',
                }}
                onClick={onClickBackButton}
              />
            </AppTooltip>
            {loading ? (
              <Skeleton width={150} height={75} />
            ) : (
              <Box
                display={'flex'}
                alignItems={'center'}
                sx={{
                  cursor: 'auto',
                  [`& .${dividerClasses.root}`]: {
                    height: 20,
                  },
                }}
              >
                <Box title={'Last run: ' + lastRun?.Status}>
                  {lastRun?.Status === 'Queued' && (
                    <AccessTimeFilledIcon color='primary' />
                  )}

                  {lastRun?.Status === 'Failed' && <CancelIcon color='error' />}

                  {lastRun?.Status === 'Succeeded' && (
                    <CheckCircleIcon color='success' />
                  )}

                  {lastRun?.Status === 'Running' && (
                    <CircularProgress size={20} />
                  )}
                </Box>

                <ListItemText
                  sx={{ ml: 4 }}
                  primary={
                    <>
                      <Typography variant='h4'>
                        {item.Name}{' '}
                        <IconButton
                          title='Refresh'
                          size='small'
                          onClick={refetch}
                        >
                          <RefreshIcon fontSize='inherit' />
                        </IconButton>
                      </Typography>{' '}
                    </>
                  }
                  secondary={
                    DockerAppTypes.find(
                      (e) => e.Value === lastRun.DeploymentType,
                    )?.Description || '-'
                  }
                  primaryTypographyProps={{
                    component: 'div',
                  }}
                />
              </Box>
            )}
          </Box>

          {loading ? (
            <Skeleton width={100} />
          ) : (
            <Box
              display={'flex'}
              alignItems={'center'}
              sx={{
                [`& .${dividerClasses.root}`]: {
                  height: 20,
                },
              }}
            >
              <Button
                variant='outlined'
                title='Deployment New Version'
                onClick={goToUpgrade}
              >
                Deployment New Version
              </Button>
              <Divider orientation='vertical' variant='middle' sx={{ ml: 2 }} />
              <DeleteDockerImageAction
                selected={item}
                trigger={
                  <IconButton variant='outlined' color='error' title='Delete'>
                    <DeleteIcon />
                  </IconButton>
                }
                handleSubmitDelete={onClickBackButton}
              />
            </Box>
          )}
        </Box>
      </AppsHeader>

      <SimpleBar
        style={{ height: 'calc(100vh - 225px)', marginBottom: '20px' }}
      >
        <Box p={4}>
          {loading && <Code />}
          {!loading && (
            <Grid container spacing={5}>
              {/* <Grid item xs={12}>
              <Accordion
                defaultExpanded
                sx={{
                  boxShadow: 'none',
                  backgroundColor: grey[50],
                  pl: 5,
                  pr: 5,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h4'> Image Info </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                      <ListItemText
                        primary='Image Name'
                        secondary={item.Name ?? '-'}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <ListItemText
                        primary='Deployment Type'
                        secondary={
                          DockerAppTypes.find(
                            (e) => e.Value === item.DeploymentType,
                          )?.Description || '-'
                        }
                      />
                    </Grid>
                    {!DockerAppTypes.find(
                      (e) => e.Value === item.DeploymentType,
                    )?.IsCron && (
                      <Grid item xs={12} sm={3}>
                        <ListItemText
                          primary='Target Port'
                          secondary={item.TargetPort ?? '-'}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12}>
                      <ListItemText
                        primary='Required Environment Variables'
                        secondary={item.RequiredEnviromentVariables?.split(
                          ',',
                        ).map((e) => (
                          <Chip
                            component={'span'}
                            key={e}
                            label={e}
                            size='small'
                            sx={{ mr: 2 }}
                          />
                        ))}
                        secondaryTypographyProps={{
                          sx: {
                            mt: 1,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid> */}
              {/* <Grid item xs={12}>
              <Accordion
                defaultExpanded
                sx={{
                  boxShadow: 'none',
                  backgroundColor: grey[50],
                  pl: 5,
                  pr: 5,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h4'> Image Source </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12}>
                      <ListItemText
                        primary='Repository Url'
                        secondary={item.RepositoryUrl ?? ''}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ListItemText
                        primary='Branch Name'
                        secondary={item.BranchName ?? '-'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ListItemText
                        primary='Dockerfile Path'
                        secondary={item.DockerfilePath ?? '-'}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid> */}
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Accordion
                    defaultExpanded
                    sx={{
                      boxShadow: 'none',
                      backgroundColor: grey[50],
                      pl: 5,
                      pr: 5,
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant='h4'> Last Run</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={5}>
                        <Grid item xs={12} sm={4}>
                          <ListItemText
                            primary='Image Name'
                            secondary={lastRun.ImageName ?? '-'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ListItemText
                            primary='Image ID'
                            secondary={lastRun.ImageId ?? '-'}
                            secondaryTypographyProps={{
                              title: lastRun.ImageId,
                              sx: {
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <ListItemText
                            primary='Deployment Type'
                            secondary={
                              DockerAppTypes.find(
                                (e) => e.Value === lastRun.DeploymentType,
                              )?.Description || '-'
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ListItemText
                            primary='Repository Url'
                            secondary={lastRun.RepositoryUrl ?? '-'}
                            secondaryTypographyProps={{
                              title: lastRun.RepositoryUrl,
                              sx: {
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ListItemText
                            primary='Branch Name'
                            secondary={lastRun.BranchName ?? '-'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ListItemText
                            primary='Dockerfile Path'
                            secondary={lastRun.DockerfilePath ?? '-'}
                          />
                        </Grid>
                        {!isCronApp && (
                          <Grid item xs={12} sm={4}>
                            <ListItemText
                              primary='Target Port'
                              secondary={lastRun.TargetPort ?? '-'}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={4}>
                          <ListItemText
                            primary='Tag'
                            secondary={lastRun.Tag ?? '-'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ListItemText
                            primary='Status'
                            secondary={
                              lastRun.Status ? (
                                <Box
                                  component={'span'}
                                  color={
                                    lastRun.Status === 'Succeeded'
                                      ? 'success.main'
                                      : lastRun.Status === 'Failed'
                                      ? 'error.main'
                                      : undefined
                                  }
                                  size='small'
                                  sx={{
                                    fontSize: 12,
                                    textTransform: 'uppercase',
                                  }}
                                >
                                  {lastRun.Status}{' '}
                                  <Link
                                    to={ROUTE_STRING.DOCKER_IMAGE.LOG.replace(
                                      ':repository',
                                      repository,
                                    )}
                                    color='primary.main'
                                    style={{
                                      fontSize: 12,
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    (View more)
                                  </Link>
                                </Box>
                              ) : (
                                '-'
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ListItemText
                            primary='Created On'
                            secondary={
                              lastRun.CreatedAt
                                ? formatDateFromISO(
                                    lastRun.CreatedAt,
                                    inputFormatDateTime,
                                  )
                                : '-'
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <ListItemText
                            primary='Required Environment Variables'
                            secondary={lastRun.RequiredEnviromentVariables?.split(
                              ',',
                            ).map((e) => (
                              <Chip
                                component={'span'}
                                key={e}
                                label={e}
                                size='small'
                                sx={{ mr: 2 }}
                              />
                            ))}
                            secondaryTypographyProps={{
                              sx: {
                                mt: 1,
                              },
                            }}
                          />
                        </Grid>
                        {/* <Grid item xs={12} sm={12}>
                          <ListItemText
                            primary='Error Message'
                            secondary={
                              lastRun.ErrorMessage ? (
                                <SimpleBar style={{ maxHeight: 400 }}>
                                  <Alert icon={false} severity='error'>
                                    {lastRun.ErrorMessage}
                                  </Alert>
                                </SimpleBar>
                              ) : (
                                '-'
                              )
                            }
                            secondaryTypographyProps={{
                              component: 'div',
                              sx: {
                                whiteSpace: 'pre',
                              },
                            }}
                          />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={12}>
                          <ListItemText
                            primary='Logs'
                            secondary={
                              lastRun.Logs ? (
                                <SimpleBar style={{ maxHeight: 400 }}>
                                  <Alert icon={false} severity='info'>
                                    {lastRun.Logs}
                                  </Alert>
                                </SimpleBar>
                              ) : (
                                '-'
                              )
                            }
                            secondaryTypographyProps={{
                              component: 'div',
                              sx: {
                                whiteSpace: 'pre',
                              },
                            }}
                          />
                        </Grid> */}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h4' mt={5} mb={5}>
                  Image Versions
                </Typography>
                <DockerImageVersion rows={item.Tags ?? []} />
              </Grid>
            </Grid>
          )}
        </Box>
      </SimpleBar>
    </>
  );
};

export default React.memo(Detail);
