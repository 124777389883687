import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { getListLogContainerAppQuery } from '../apis';
import { setFieldContainerApp } from '..';
import { deepClone } from '@tenant/utility/helper/Utils';
import { useDispatch } from 'react-redux';

const useGetListLogContainerApp = ({
  appInstanceId,
  containerAppId,
  startDate,
  endDate,
}) => {
  const dispatch = useDispatch();

  const getData = useQuery({
    queryKey: [
      'container-app-log-list' +
        JSON.stringify({
          appInstanceId,
          containerAppId,
          startDate,
          endDate,
        }),
    ],
    queryFn: () =>
      getListLogContainerAppQuery({
        appInstanceId,
        containerAppId,
        startDate,
        endDate,
      }),
  });

  useEffect(() => {
    const data = typeof getData !== 'undefined' ? getData?.data?.data : [];

    dispatch(
      setFieldContainerApp({
        field: 'logFilter',
        value: { startDate, endDate },
      }),
    );

    if (data) {
      dispatch(
        setFieldContainerApp({
          field: 'log',
          value: deepClone(data),
        }),
      );
    }
  }, [getData?.data, startDate, endDate, dispatch]);

  return getData;
};

export default useGetListLogContainerApp;
