import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Information from './InformationStep';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Stack,
} from '@mui/material';
import ConfirmStep from './ConfirmStep';
import { Form, Formik } from 'formik';
import { validateStepInformation } from 'pages/publishApp/helpers/validate';
import {
  StepInformation,
  StepKeyBenefit,
  StepFeature,
  StepConfirm,
  StepMobile,
} from 'pages/publishApp/helpers/constants';
import { useAuthUser } from '@tenant/utility/AuthHooks';
import KeyBenefitStep from './KeyBenefitStep';
import FeatureStep from './FeatureStep';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import { LoadingButton } from '@mui/lab';
import useModal from '@tenant/utility/hooks/useModal';
import isEmpty from 'lodash/isEmpty';
import {
  APP_CONNECTIVITY_STATE_ENUM,
  APP_TYPE_ENUM,
  PLATFORM_ENUM,
} from '@tenant/utility/constants/enum';
import { validatePublishApp } from 'pages/listApp/helpers/validate';
import { ACTION_MODE_ENUM, StatusAppType } from 'constants/AppEnums';
import AppVersion from 'pages/listApp/components/PublishApp/AppVersion';
import AppVersionLog from 'pages/listApp/components/PublishApp/AppVersionLog';
import MobileStep from './MobileStep';

const PublishAppStep = ({ mode, input, submit, isPending, isSuccess }) => {
  const formRef = React.useRef(null);
  const { user } = useAuthUser();

  const {
    visible: modalCreateVisible,
    onShow: onShowModalCreate,
    onClose: onCloseModalCreate,
  } = useModal();

  const {
    visible: modalEditVisible,
    onShow: onShowModalEdit,
    onClose: onCloseModalEdit,
  } = useModal();

  const [activeStep, setActiveStep] = React.useState(StepInformation);
  const [skipped, setSkipped] = React.useState(new Set());
  const [submitValue, setSubmitValue] = React.useState();
  const [currentValues, setCurrentValues] = React.useState({});

  const isHaveFeatureStep = React.useMemo(() => {
    if (isEmpty(currentValues)) {
      const IntegrationMode = input?.IntegrationMode;
      if (IntegrationMode === APP_TYPE_ENUM.Embed) return true;
      return false;
    }
    const IntegrationMode = currentValues.IntegrationMode;
    return IntegrationMode === APP_TYPE_ENUM.Embed;
  }, [currentValues, input]);

  const isHaveMobileStep = React.useMemo(() => {
    if (isEmpty(currentValues)) {
      const platform = input?.Platform;
      const result =
        platform === PLATFORM_ENUM['Mobile'] ||
        platform === PLATFORM_ENUM['Cross_Platform'];
      console.log(result);
      return result;
    }
    const platform = currentValues.Platform;
    return (
      platform === PLATFORM_ENUM['Mobile'] ||
      platform === PLATFORM_ENUM['Cross_Platform']
    );
  }, [currentValues, input]);

  const steps = React.useMemo(() => {
    if (isHaveMobileStep) {
      return [StepInformation, StepMobile, StepKeyBenefit, StepConfirm];
    }

    if (isHaveFeatureStep) {
      return [StepInformation, StepKeyBenefit, StepFeature, StepConfirm];
    }

    return [StepInformation, StepKeyBenefit, StepConfirm];
  }, [currentValues, input]);

  const nextStepKey = React.useMemo(() => {
    const currentIndex = steps.indexOf(activeStep);
    if (currentIndex === -1 || currentIndex === steps.length - 1) {
      return null; // No next step
    }
    return steps[currentIndex + 1];
  }, [currentValues, input, activeStep]);

  const prevStepKey = React.useMemo(() => {
    const currentIndex = steps.indexOf(activeStep);
    if (currentIndex <= 0) {
      return null; // No previous step
    }
    return steps[currentIndex - 1];
  }, [currentValues, input, activeStep]);

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async (values) => {
    const validate = await formRef.current?.validateForm();
    if (Object.keys(validate).length > 0) {
      return;
    }
    if (activeStep === StepConfirm) {
      formRef.current?.submitForm();
      return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(nextStepKey);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevStepKey);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(nextStepKey);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const transformMetaDataSubmit = (data) => {
    const isValidFeatureItem = (feature) => {
      return feature.source && feature.featureName;
    };
    return data
      .map((entry) => {
        const slots = entry.slots.reduce((acc, slot) => {
          let listFeature =
            slot.features?.map((feature) => {
              const result = {
                ...feature,
              };

              if (slot.type === 'slotAdditionMenu') {
                result.extraData = {
                  tag: 'li',
                };
              }

              return result;
            }) ?? [];

          listFeature = listFeature.filter((feature) =>
            isValidFeatureItem(feature),
          );

          if (listFeature?.length) {
            acc[slot.type] = listFeature;
          }

          return acc;
        }, {});

        return {
          ...entry,
          slots,
        };
      })
      .filter((entry) => !isEmpty(entry.slots));
  };

  const handleSubmitEdit = async (values) => {
    const finalSubmitValues = {
      ...submitValue,
      ...values,
    };
    setSubmitValue(finalSubmitValues);
    handleSubmit(finalSubmitValues);
  };

  const handleSubmit = (values) => {
    const data = {
      ...values,
      MetaData: JSON.stringify(
        transformMetaDataSubmit(values.PageSlots),
        null,
        2,
      ),
      CreatedBy: user.localAccountId,
      IconFile: undefined,
      CategoryIds: values.CategoryIds.map((e) => e.Id),
      IconBlobReference: values.IconFile,
    };

    // Validate this one only in case Create app
    if (mode === ACTION_MODE_ENUM['Create']) {
      if (data.AppVersion) delete data.AppVersion;
      if (data.AppVersionNumber) delete data.AppVersionNumber;
    }

    // For mobile, check app connectivity state to remove un-use content
    const isSupportMobile =
      values.Platform === PLATFORM_ENUM['Mobile'] ||
      values.Platform === PLATFORM_ENUM['Cross_Platform'];
    if (isSupportMobile) {
      const appConnectivityState = values.AppConnectivityState;
      if (appConnectivityState === APP_CONNECTIVITY_STATE_ENUM['Online']) {
        data.MobileAppUrl = values.MobileFeatureUrl;
        delete data.MobileFeatureUrl;
      }
    }

    const formData = new FormData();

    formData.append('IconBlobReference', data.IconBlobReference || null);

    submit(
      { ...data, AppIconFile: formData.get('IconBlobReference') },
      {
        onSuccess: () => {
          navigate(ROUTE_STRING.LIST_APP.LIST);
        },
      },
    );
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='sm'
        open={modalCreateVisible}
        onClose={onCloseModalCreate}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' fontSize={16}>
          Confirm Finish
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Do you agree to create app?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isPending && !isSuccess}
            onClick={onCloseModalCreate}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            loading={isPending && !isSuccess}
            onClick={() => handleSubmit(submitValue)}
            variant='contained'
          >
            Agree
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog open={modalEditVisible} onClose={onCloseModalEdit}>
        <DialogTitle sx={{ fontSize: 20 }}>
          Are you sure you want to edit this app?
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              AppVersion: input?.AppVersion,
              Platform: input?.Platform,
              MobileAppVersion: input?.MobileAppVersion,
            }}
            validationSchema={validatePublishApp}
            onSubmit={handleSubmitEdit}
          >
            {({ values, errors, setFieldValue, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Stack direction='column' spacing={4}>
                  {input.Platform == PLATFORM_ENUM['Web'] && (
                    <>
                      <AppVersion
                        title={'Web App Version'}
                        fieldKey={'AppVersion'}
                        errorText={errors.AppVersion}
                        valueText={values.AppVersion}
                        setFieldValue={setFieldValue}
                      />

                      <AppVersionLog
                        title={'Web App Version Log'}
                        fieldKey={'AppVersionLog'}
                        errorText={errors.AppVersionLog}
                        valueText={values.AppVersionLog}
                        setFieldValue={setFieldValue}
                      />
                    </>
                  )}

                  {input.Platform == PLATFORM_ENUM['Mobile'] && (
                    <>
                      <AppVersion
                        title={'Mobile App Version'}
                        fieldKey={'MobileAppVersion'}
                        errorText={errors.MobileAppVersion}
                        valueText={values.MobileAppVersion}
                        setFieldValue={setFieldValue}
                      />

                      <AppVersionLog
                        title={'Mobile App Version Log'}
                        fieldKey={'MobileAppVersionLog'}
                        errorText={errors.MobileAppVersionLog}
                        valueText={values.MobileAppVersionLog}
                        setFieldValue={setFieldValue}
                      />
                    </>
                  )}

                  {input.Platform == PLATFORM_ENUM['Cross_Platform'] && (
                    <>
                      <AppVersion
                        title={'Web App Version'}
                        fieldKey={'AppVersion'}
                        errorText={errors.AppVersion}
                        valueText={values.AppVersion}
                        setFieldValue={setFieldValue}
                      />

                      <AppVersionLog
                        title={'Web App Version Log'}
                        fieldKey={'AppVersionLog'}
                        errorText={errors.AppVersionLog}
                        valueText={values.AppVersionLog}
                        setFieldValue={setFieldValue}
                      />

                      <AppVersion
                        title={'Mobile App Version'}
                        fieldKey={'MobileAppVersion'}
                        errorText={errors.MobileAppVersion}
                        valueText={values.MobileAppVersion}
                        setFieldValue={setFieldValue}
                      />

                      <AppVersionLog
                        title={'Mobile App Version Log'}
                        fieldKey={'MobileAppVersionLog'}
                        errorText={errors.MobileAppVersionLog}
                        valueText={values.MobileAppVersionLog}
                        setFieldValue={setFieldValue}
                      />
                    </>
                  )}
                </Stack>
                <Stack
                  spacing={2}
                  direction='row'
                  sx={{ mt: 2 }}
                  justifyContent='flex-end'
                >
                  <Button
                    variant='text'
                    color='secondary'
                    onClick={onCloseModalEdit}
                  >
                    Cancel
                  </Button>
                  <Button variant='contained' color='primary' type='submit'>
                    Edit
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={input}
        onSubmit={handleSubmit}
        validationSchema={validateStepInformation[activeStep]}
        innerRef={formRef}
      >
        {({
          submitForm,
          isSubmitting,
          values,
          setFieldValue,
          errors,
          touched,
          handleBlur,
          handleChange,
          validateField,
        }) => (
          <Box sx={{ position: 'relative' }}>
            <Stepper
              activeStep={steps.indexOf(activeStep)}
              alternativeLabel
              sx={{ pb: 2, borderBottom: '1px solid #ddd' }}
            >
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant='caption'>Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Box
              sx={{
                height: 'calc(100vh - 290px)',
                overflow: 'auto',
                ml: -2,
                mr: -2,
              }}
            >
              <Fade in={true} timeout={300}>
                <Box p={5} mt={5}>
                  {activeStep === StepInformation && (
                    <Information
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      setCurrentValues={setCurrentValues}
                    />
                  )}
                  {activeStep === StepMobile && (
                    <MobileStep
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      setCurrentValues={setCurrentValues}
                    />
                  )}
                  {activeStep === StepKeyBenefit && (
                    <KeyBenefitStep
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {activeStep === StepFeature && (
                    <FeatureStep
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {activeStep === StepConfirm && (
                    <ConfirmStep values={values} />
                  )}
                </Box>
              </Fade>
            </Box>

            <FooterWrapper
              className='footer'
              sx={{
                margin: '0px -20px 0px -20px',
                backgroundColor: '#f4f7fe',
                height: '50px',
              }}
            >
              <Stack
                direction='row'
                justifyContent='space-between'
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  height: '100%',
                  p: 2,
                }}
              >
                <Button
                  color='inherit'
                  disabled={
                    steps.indexOf(activeStep) === 0 || (isPending && !isSuccess)
                  }
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {isStepOptional(activeStep) && (
                  <Button color='inherit' onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}

                <LoadingButton
                  loading={isPending && !isSuccess}
                  onClick={() => {
                    if (steps.indexOf(activeStep) === steps.length - 1) {
                      setSubmitValue({ ...values });
                      // Check mode to show dialog create or edit
                      const isSupportMobile =
                        values.Platform === PLATFORM_ENUM['Mobile'] ||
                        values.Platform === PLATFORM_ENUM['Cross_Platform'];
                      const isShowModalEdit =
                        mode === ACTION_MODE_ENUM['Edit'] &&
                        isSupportMobile &&
                        values.Status === StatusAppType['ACTIVE'];

                      if (isShowModalEdit) {
                        onShowModalEdit();
                      } else {
                        onShowModalCreate();
                      }

                      return;
                    }
                    handleNext({ ...values });
                  }}
                >
                  {steps.indexOf(activeStep) === steps.length - 1
                    ? 'Finish'
                    : 'Next'}
                </LoadingButton>
              </Stack>
            </FooterWrapper>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default React.memo(PublishAppStep);
