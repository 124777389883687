import React from 'react';
import clsx from 'clsx';
import SearchIcon from '@mui/icons-material/Search';
import {
  SearchIconBox,
  SearchIconWrapper,
  SearchInputBase,
  SearchWrapper,
} from './index.style';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

const AppSearch = ({
  placeholder,
  iconPosition,
  align,
  overlap,
  onlyIcon,
  disableFocus,
  iconStyle,
  isClearable,
  searchValue,
  onClearSearch,
  ...rest
}) => {
  return (
    <SearchWrapper sx={rest.sx} iconPosition={iconPosition}>
      <SearchIconBox
        align={align}
        className={clsx(
          'searchRoot',
          { 'hs-search': overlap },
          { 'hs-disableFocus': disableFocus },
          { searchIconBox: onlyIcon },
        )}
      >
        <SearchIconWrapper
          className={clsx({
            right: true,
          })}
          style={{ ...iconStyle, pointerEvents: isClearable ? 'auto' : 'none' }}
        >
          {isClearable &&
            (searchValue ? (
              <Tooltip title='Clear'>
                <span>
                  <IconButton disabled={!searchValue} onClick={onClearSearch}>
                    <ClearIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <SearchIcon />
            ))}

          {!isClearable && <SearchIcon />}
        </SearchIconWrapper>
        <SearchInputBase
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.target.blur();
            }
          }}
          {...rest}
          placeholder={placeholder || 'Search…'}
          inputProps={{ 'aria-label': 'search' }}
          value={searchValue}
        />
      </SearchIconBox>
    </SearchWrapper>
  );
};

export default AppSearch;
