import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { upgradeVersionAppInstanceQuery } from '../apis';

const useUpgradeVersionAppInstance = () => {
  const mutationAction = useMutation({
    mutationFn: upgradeVersionAppInstanceQuery,
    onSuccess: () => {
      toast.success('Deployment New Version app package successfully');
    },
    onError: (error) => {
      const message = error?.response?.data?.message ?? 'Server error';
      toast.error(message);
      console.error('Error Deployment New Version app package:', error);
    },
  });

  return mutationAction;
};

export default useUpgradeVersionAppInstance;
