import { Box, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppTooltip from '@tenant/core/AppTooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IntlMessages from '@tenant/utility/IntlMessages';
import { ROUTE_STRING } from 'constants/RouteString';
import { Formik } from 'formik';
import validationSchema from '../../redux/slices/dockerImage/constants/validationSchema';
import DockerAppTypes from 'constants/DockerAppTypes';
import useGetDetailDockerImage from 'redux/slices/dockerImage/hooks/useGetDetailDockerImage';
import useAddDockerImage from 'redux/slices/dockerImage/hooks/useAddDockerImage';
import { Code } from 'react-content-loader';
import AddDockerImageForm from './components/Common/AddDockerImageForm';
import { useQueryClient } from '@tanstack/react-query';
import queryKeyDockerImage from 'redux/slices/dockerImage/constants/queryKeyDockerImage';

const IS_UPGRADE = true;

const UpgradeVersion = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { repository } = useParams();
  const { data, isLoading, isRefetching, isError } =
    useGetDetailDockerImage(repository);
  const addItem = useAddDockerImage(IS_UPGRADE);
  const queryClient = useQueryClient();

  const loading = isLoading || isRefetching;

  const initialValues = data?.data?.LastRun ?? {};

  const onClickBackButton = () => {
    const canGoBack = location.key !== 'default';
    canGoBack
      ? navigate(-1)
      : navigate(
          ROUTE_STRING.DOCKER_IMAGE.VIEW.replace(':repository', repository),
        );
  };

  const handleSubmit = (data) => {
    addItem.mutate(
      {
        ...data,
        DeploymentType: data.DeploymentType?.Value,
        TargetPort: +data.TargetPort,
        RequiredEnviromentVariables: data.RequiredEnviromentVariables.split(
          /\r?\n/,
        )
          .map((line) => line.trim())
          .join(','),
      },
      {
        onSuccess() {
          queryClient.removeQueries(queryKeyDockerImage.getDetail(repository));
          onClickBackButton();
        },
      },
    );
  };

  if (isError) {
    return <Error404 />;
  }

  return (
    <>
      <AppsHeader>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              gap: 4,
            }}
            component='span'
            mr={{ xs: 2, sm: 4 }}
          >
            <AppTooltip title={<IntlMessages id='common.back' />}>
              <ArrowBackIcon
                sx={{
                  color: 'text.secondary',
                }}
                onClick={onClickBackButton}
              />
            </AppTooltip>
            <Typography variant='h4' sx={{ cursor: 'auto' }}>
              Deployment New Version
            </Typography>
          </Box>
        </Box>
      </AppsHeader>

      <Box p={4}>
        {loading && <Code />}
        {!loading && (
          <Formik
            enableReinitialize
            validateOnChange={true}
            validateOnBlur={false}
            initialValues={{
              ...initialValues,
              DeploymentType: DockerAppTypes.find(
                (e) =>
                  e.Value.toString() ===
                  initialValues.DeploymentType?.toString(),
              ),
              RequiredEnviromentVariables:
                initialValues.RequiredEnviromentVariables?.split(',')?.join(
                  '\n',
                ),
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ submitForm, values, setFieldValue, errors, validateField }) => (
              <AddDockerImageForm
                isUpgrade
                validateField={validateField}
                setForm={setFieldValue}
                submitForm={submitForm}
                onCancel={onClickBackButton}
                loading={addItem.isPending}
                values={values}
                errors={errors}
              />
            )}
          </Formik>
        )}
      </Box>
    </>
  );
};

export default React.memo(UpgradeVersion);
