import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Stack, Tab, TextField } from '@mui/material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import DockerImageCollapseCard from '../Add/DockerImageCollapseCard';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import useNextTick from '@tenant/utility/hooks/useNextTick';
import { grey } from '@mui/material/colors';
import { deepClone } from '@mui/x-data-grid/utils/utils';

const AppPackageAddForm = ({
  onCancel = () => {},
  submitForm = () => {},
  setForm = () => {},
  validateField = () => {},
  handleChangeIsValidAppContainer = () => {},
  values = {},
  errors = {},
  loading = false,
  isValidAppContainer = false,
}) => {
  const [tab, setTab] = useState('1');
  const nextTick = useNextTick();

  const setFieldValue = async (field, value, isTrigger = true) => {
    setForm(field, value, false);
    if (isTrigger) {
      await nextTick();
      validateField(field);
    }
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          height: 'calc(100vh - 285px)',
          overflow: 'auto',
        }}
      >
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label='lab API tabs example'
                sx={{
                  '& .information ': {
                    color:
                      (!!errors.Name || !!errors.Description) &&
                      '#d32f2f !important',
                  },
                  '& .containers ': {
                    color: !isValidAppContainer && '#d32f2f !important',
                  },
                }}
              >
                <Tab className='information' label='Information' value='1' />
                <Tab className='containers' label='Containers' value='2' />
              </TabList>
            </Box>
            <Box mt={3}>
              <TabPanel value='1'>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={12}>
                    <Box
                      component='p'
                      color='text.primary'
                      fontSize={14}
                      mb={2}
                    >
                      Name
                      <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                        *
                      </Box>
                    </Box>
                    <TextField
                      fullWidth
                      value={values.Name ?? ''}
                      error={!!errors.Name}
                      helperText={errors.Name}
                      placeholder='E.g. Joblogic application'
                      onChange={(e) =>
                        setFieldValue('Name', e.target.value, true)
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box
                      component='p'
                      color='text.primary'
                      fontSize={14}
                      mb={2}
                    >
                      Description{' '}
                      <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                        *
                      </Box>
                    </Box>
                    <TextField
                      fullWidth
                      multiline
                      rows={5}
                      value={values.Description ?? ''}
                      error={!!errors.Description}
                      helperText={errors.Description}
                      placeholder='E.g. Describe your application'
                      onChange={(e) =>
                        setFieldValue('Description', e.target.value, true)
                      }
                      sx={{
                        '& .Mui-disabled': { background: grey[50] },
                      }}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value='2'>
                <DockerImageCollapseCard
                  isError={!isValidAppContainer}
                  initialValues={deepClone(values.AppComponents ?? [])}
                  handleChange={(newValue, isValid) => {
                    setFieldValue('AppComponents', deepClone(newValue));
                    handleChangeIsValidAppContainer(
                      newValue?.length > 0 && isValid,
                    );
                  }}
                />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </Box>

      <FooterWrapper
        className='footer'
        sx={{
          margin: '0px -20px 0px -20px',
          backgroundColor: '#f4f7fe',
          height: '50px',
        }}
      >
        <Stack
          direction='row'
          justifyContent='end'
          sx={{
            position: 'sticky',
            bottom: 0,
            height: '100%',
            p: 2,
          }}
        >
          <Button variant='outlined' onClick={onCancel} sx={{ mr: 3 }}>
            Cancel
          </Button>

          <LoadingButton
            variant='contained'
            loading={loading}
            sx={{ mr: 3 }}
            onClick={submitForm}
          >
            Save
          </LoadingButton>
        </Stack>
      </FooterWrapper>
    </Box>
  );
};

export default React.memo(AppPackageAddForm);

AppPackageAddForm.propTypes = {};
