export const ROUTE_STRING = {
  LIST_APP: {
    LIST: '/list-app',
    DETAIL: '/list-app/:appId',
    EDIT: '/list-app/:appId/edit',
    EDIT_FEATURE: '/list-app/:appId/edit-feature',
  },
  PUBLISH_APP: '/publish-app',
  DOCKER_IMAGE: {
    LIST: '/docker-images',
    ADD: '/docker-images/add',
    VIEW: '/docker-images/:repository',
    UPGRADE_VERSION: '/docker-images/:repository/upgrade-version',
    LOG: '/docker-images/:repository/logs',
  },
  APP_PACKAGE: {
    LIST: '/app-packages',
    ADD: '/app-packages/add',
    VIEW: '/app-packages/:id',
  },
  APP_INSTANCE: {
    LIST: '/app-instances',
    ADD: '/app-instances/add',
    VIEW: '/app-instances/:id',
  },
  CONTAINER_APP: {
    VIEW: '/container-app/:appInstanceId/:id',
  },
};
