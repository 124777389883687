import React from 'react';
import PropTypes from 'prop-types';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import { Alert, Box, Tab } from '@mui/material';
import AppTooltip from '@tenant/core/AppTooltip';
import IntlMessages from '@tenant/utility/IntlMessages';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ROUTE_STRING } from 'constants/RouteString';
import { useNavigate, useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import useGetDetailDockerImage from 'redux/slices/dockerImage/hooks/useGetDetailDockerImage';
import SimpleBar from 'simplebar-react';
import { Code } from 'react-content-loader';
const ViewLog = () => {
  const [value, setValue] = React.useState('Logs');
  const { repository } = useParams();
  const { data, isLoading, isRefetching } = useGetDetailDockerImage(repository);
  const lastRun = data?.data?.LastRun ?? {};
  const loading = isLoading || isRefetching;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const onClickBackButton = () => {
    navigate(ROUTE_STRING.DOCKER_IMAGE.VIEW.replace(':repository', repository));
  };

  return (
    <TabContext value={value}>
      <AppsHeader>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              gap: 4,
            }}
            component='span'
            mr={{ xs: 2, sm: 4 }}
          >
            <AppTooltip title={<IntlMessages id='common.back' />}>
              <ArrowBackIcon
                sx={{
                  color: 'text.secondary',
                }}
                onClick={onClickBackButton}
              />
            </AppTooltip>

            <Box sx={{ ml: 2, mb: -3 }}>
              <TabList
                onChange={handleChange}
                aria-label='lab API tabs example'
              >
                <Tab label='Logs' value='Logs' />
                <Tab label='Error Message' value='Error Message' />
              </TabList>
            </Box>
          </Box>
        </Box>
      </AppsHeader>
      <Box
        p={4}
        sx={{ backgroundColor: value === 'Logs' ? '#e5f6fd' : '#fdeded' }}
      >
        <TabPanel value='Logs'>
          {loading && (
            <Box sx={{ height: 'calc(100vh - 275px)' }}>
              <Code />
            </Box>
          )}

          {lastRun.Logs ? (
            <SimpleBar
              style={{
                height: 'calc(100vh - 275px)',
                whiteSpace: 'pre',
                marginRight: '-16px',
              }}
            >
              <Alert icon={false} severity='info'>
                {lastRun.Logs}
              </Alert>
            </SimpleBar>
          ) : (
            '-'
          )}
        </TabPanel>
        <TabPanel value='Error Message'>
          {lastRun.ErrorMessage ? (
            <SimpleBar
              style={{
                height: 'calc(100vh - 275px)',
                whiteSpace: 'pre',
                marginRight: '-16px',
                lineHeight: '2',
              }}
            >
              <Alert icon={false} severity='error'>
                {lastRun.ErrorMessage}
              </Alert>
            </SimpleBar>
          ) : (
            '-'
          )}
        </TabPanel>
      </Box>
    </TabContext>
  );
};

export default React.memo(ViewLog);

ViewLog.propTypes = {};
