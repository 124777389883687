import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Divider,
  dividerClasses,
  Grid,
  IconButton,
  ListItemText,
  Skeleton,
  Tab,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppTooltip from '@tenant/core/AppTooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IntlMessages from '@tenant/utility/IntlMessages';
import { ROUTE_STRING } from 'constants/RouteString';
import { Formik } from 'formik';
import { grey } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { setFieldAppInstance } from 'redux/slices/appInstance';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import useGetDetailAppInstance from 'redux/slices/appInstance/hooks/useGetDetailAppInstance';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useModal from '@tenant/utility/hooks/useModal';
import AddEnvironmentDrawer from './components/Add/AddEnvironmentDrawer';
import { Code } from 'react-content-loader';
import IdpClientTable from './components/Detail/IdpClients/IdpClientTable';
import IdpClientModalForm from './components/Detail/IdpClients/IdpClientModalForm';
import useAddClientByAppInstance from 'redux/slices/appInstance/hooks/useAddClientByAppInstance';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StartAndStopProgress from 'pages/containerApp/Common/StartAndStopProgress';
import DockerAppTypes from 'constants/DockerAppTypes';
import useUpgradeVersionAppInstance from 'redux/slices/appInstance/hooks/useUpgradeVersionAppInstance';
import SimpleBar from 'simplebar-react';
import checkCanStopContainerApp from 'redux/slices/appInstance/helpers/checkCanStopContainerApp';
import useStartAppContainer from 'redux/slices/containerApp/hooks/useStartAppContainer';
import useStopAppContainer from 'redux/slices/containerApp/hooks/useStopAppContainer';
import AppLoader from '@tenant/core/AppLoader';
import { CONTAINER_APP_STATUS_ENUM } from '@tenant/utility/constants/enum';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteAppInstanceAction from './components/Common/DeleteAppInstanceAction';
import DeleteIcon from '@mui/icons-material/Delete';

const Detail = () => {
  const { id } = useParams();
  const { data, isLoading, isRefetching, isError, refetch } =
    useGetDetailAppInstance(id);
  const loading = isLoading || isRefetching;
  const addClientMutate = useAddClientByAppInstance();
  const upgradeVersionMutate = useUpgradeVersionAppInstance();
  const startMutate = useStartAppContainer();
  const stopMutate = useStopAppContainer();
  const item = data?.data;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { visible, onClose, onShow } = useModal();
  const {
    visible: visibleAddClient,
    onClose: onCloseAddClient,
    onShow: onShowAddClient,
  } = useModal();

  const [containerApp, setContainerApp] = useState();
  const [newClients, setNewClients] = useState([]);
  const [tab, setTab] = useState('1');

  const onClickBackButton = () => {
    navigate(ROUTE_STRING.APP_INSTANCE.LIST);
    dispatch(setFieldAppInstance({ field: 'viewItem', value: null }));
  };

  const handleShow = (item) => {
    navigate(
      ROUTE_STRING.CONTAINER_APP.VIEW.replace(':id', item.Id).replace(
        ':appInstanceId',
        id,
      ),
    );
  };

  const handleClose = () => {
    setContainerApp(null);
    onClose();
  };

  const handleSubmitClient = (data) => {
    addClientMutate.mutate(data, {
      onSuccess: (result) => {
        setNewClients((s) => [...s, result?.data]);
        onCloseAddClient();
      },
    });
  };

  const handleUpgradeVersion = () => {
    upgradeVersionMutate.mutate(
      { appInstanceId: id },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  const onStartContainerApp = ({ containerAppId, appInstanceId }) => {
    startMutate.mutate(
      { containerAppId, appInstanceId },
      {
        onSuccess() {
          refetch();
        },
      },
    );
  };

  const onStopContainerApp = ({ containerAppId, appInstanceId }) => {
    stopMutate.mutate(
      { containerAppId, appInstanceId },
      {
        onSuccess() {
          refetch();
        },
      },
    );
  };

  const columns = [
    {
      field: 'ContainerAppName',
      headerName: 'Name',
      width: 300,
      sortable: true,
      flex: 1,
      renderCell: (params) => (
        <Link
          to={ROUTE_STRING.CONTAINER_APP.VIEW.replace(
            ':appInstanceId',
            id,
          ).replace(':id', params.row.Id)}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'DeploymentType',
      headerName: 'Deployment Type',
      width: 275,
      sortable: true,
      renderCell: (params) =>
        DockerAppTypes.find(
          (e) => e.Value.toString() === params.value.toString(),
        )?.Description,
    },
    {
      field: 'Image',
      headerName: 'Image',
      width: 275,
      sortable: true,
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Typography
          color={
            checkCanStopContainerApp(params.value) ? 'success.main' : 'error'
          }
        >
          {checkCanStopContainerApp(params.value) ? 'RUNNING' : 'STOPPED'}
        </Typography>
      ),
    },
    {
      field: 'Action',
      headerName: '',
      width: 50,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          sx={{ minWidth: 150 }}
          key='app-request-action--approve'
          label='View'
          showInMenu
          onClick={() => handleShow(params.row)}
        />,
        checkCanStopContainerApp(params.row.Status) ? (
          <GridActionsCellItem
            key='app-request-action--stop'
            label='Stop'
            showInMenu
            onClick={() =>
              onStopContainerApp({
                containerAppId: params.row.Id,
                appInstanceId: id,
              })
            }
          />
        ) : (
          <GridActionsCellItem
            key='app-request-action--start'
            label='Start'
            showInMenu
            onClick={() =>
              onStartContainerApp({
                containerAppId: params.row.Id,
                appInstanceId: id,
              })
            }
          />
        ),
      ],
    },
  ];

  if (isError) {
    return <Error404 />;
  }

  return (
    <>
      <AppsHeader>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              gap: 4,
            }}
            component='span'
            mr={{ xs: 2, sm: 4 }}
          >
            <AppTooltip title={<IntlMessages id='common.back' />}>
              <ArrowBackIcon
                sx={{
                  color: 'text.secondary',
                }}
                onClick={onClickBackButton}
              />
            </AppTooltip>
            {loading ? (
              <Skeleton width={50} />
            ) : (
              <Typography variant='h4' sx={{ cursor: 'auto' }}>
                {item.Name}{' '}
                <IconButton title='Refresh' size='small' onClick={refetch}>
                  <RefreshIcon fontSize='inherit' />
                </IconButton>
              </Typography>
            )}
          </Box>

          {isLoading || isRefetching ? (
            <Skeleton sx={{ width: 100 }} />
          ) : (
            <Box
              display={'flex'}
              alignItems={'center'}
              sx={{
                [`& .${dividerClasses.root}`]: {
                  height: 20,
                },
              }}
            >
              <Divider orientation='vertical' variant='middle' sx={{ ml: 2 }} />
              <DeleteAppInstanceAction
                selected={item}
                trigger={
                  <IconButton variant='outlined' color='error' title='Delete'>
                    <DeleteIcon />
                  </IconButton>
                }
                handleSubmitDelete={onClickBackButton}
              />
            </Box>
          )}
        </Box>
      </AppsHeader>

      <SimpleBar
        style={{ height: 'calc(100vh - 225px)', marginBottom: '20px' }}
      >
        {(startMutate.isPending || stopMutate.isPending) && <AppLoader />}
        <Box p={4}>
          {!(isLoading || isRefetching) && (
            <Formik
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false}
            >
              <Box>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Accordion
                      defaultExpanded
                      sx={{
                        boxShadow: 'none',
                        backgroundColor: grey[50],
                        pl: 5,
                        pr: 5,
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h4'> Information </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={5}>
                          <Grid item xs={12} sm={9}>
                            <ListItemText
                              primary='Name'
                              secondary={item.Name ?? '-'}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <ListItemText
                              primary='CPU And Memory'
                              secondary={item.CpuAndMemory ?? '-'}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TabContext value={tab}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(e, tab) => setTab(tab)}>
                          <Tab label='Container Apps' value='1' />
                          <Tab label='Idp Clients' value='2' />
                        </TabList>
                      </Box>
                      <TabPanel value='1'>
                        <Grid item xs={12} sm={12}>
                          {!data?.data?.IsLatest && (
                            <Box mb={3} mt={3}>
                              <Alert
                                severity='info'
                                action={
                                  <LoadingButton
                                    size='small'
                                    loading={upgradeVersionMutate.isPending}
                                    onClick={handleUpgradeVersion}
                                  >
                                    Upgrade
                                  </LoadingButton>
                                }
                              >
                                New updates for the container apps are now
                                available. Would you like to upgrade to the
                                latest version to take advantage of new features
                                and improvements?
                              </Alert>
                            </Box>
                          )}
                          <Box style={{ height: 450, width: '100%' }}>
                            <DataGrid
                              hideFooter
                              disableColumnSelector
                              disableColumnMenu
                              rows={item?.ContainerApps ?? []}
                              columns={columns}
                              getRowId={(row) => row.Id}
                            />
                          </Box>
                        </Grid>
                      </TabPanel>
                      <TabPanel value='2'>
                        <Grid item xs={12} sm={12}>
                          <Box
                            component='p'
                            color='text.primary'
                            fontSize={14}
                            mt={3}
                            mb={2}
                            display='flex'
                            justifyContent={'end'}
                            alignItems={'center'}
                          >
                            <Button onClick={onShowAddClient}>
                              Add Clients{' '}
                            </Button>
                          </Box>

                          <Box style={{ height: 300, width: '100%' }}>
                            <IdpClientModalForm
                              appInstanceId={id}
                              visible={visibleAddClient}
                              loading={addClientMutate.isPending}
                              handleClose={onCloseAddClient}
                              handleSubmit={handleSubmitClient}
                            />
                            <IdpClientTable
                              appInstanceId={id}
                              newClients={newClients}
                            />
                          </Box>
                        </Grid>
                      </TabPanel>
                    </TabContext>
                  </Grid>
                </Grid>

                {!!containerApp && (
                  <AddEnvironmentDrawer
                    readonly
                    visible={visible}
                    handleClose={handleClose}
                    title={containerApp.AppComponentName}
                    appType={containerApp.DeploymentType}
                    requiredEnviromentVariables={
                      Object.entries(containerApp.EnviromentVariables).map(
                        ([key, value]) => ({
                          id: key,
                          key: key,
                          value: value,
                        }),
                      ) ?? []
                    }
                    cron={containerApp?.Cron}
                  />
                )}
              </Box>
            </Formik>
          )}

          {(isLoading || isRefetching) && <Code />}
        </Box>
      </SimpleBar>
    </>
  );
};

export default React.memo(Detail);
