import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useModal from '@tenant/utility/hooks/useModal';
import { Box } from '@mui/material';
import DeleteDialog from '@tenant/core/App/Dialog/DeleteDialog';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import useDeleteAppInstance from 'redux/slices/appInstance/hooks/useDeleteAppInstance';
import queryKeyAppInstance from 'redux/slices/appInstance/constants/queryKeyAppInstance';

const DeleteAppInstanceAction = ({
  trigger,
  open,
  selected,
  handleCloseDelete = () => {},
  handleSubmitDelete = () => {},
}) => {
  const { filter } = useSelector((s) => s.appInstance);
  const queryClient = useQueryClient();
  const deleteMutate = useDeleteAppInstance();
  const { visible, onShow, onClose } = useModal();
  const visibleDelete = typeof open !== 'undefined' ? open : visible;

  const handleClose = () => {
    onClose();
    handleCloseDelete();
  };

  const handleDelete = () => {
    deleteMutate.mutate(selected, {
      onSuccess() {
        toast.success('Delete App Instance successfully');
        onClose();
        handleSubmitDelete();
        queryClient.refetchQueries(
          queryKeyAppInstance.getList(JSON.stringify(filter ?? {})),
        );
      },
    });
  };

  return (
    <>
      <Box component='span' onClick={onShow}>
        {trigger}
      </Box>
      <DeleteDialog
        visible={visibleDelete}
        loading={deleteMutate.isPending}
        name={selected?.Name}
        handleClose={handleClose}
        handleSave={handleDelete}
      />
    </>
  );
};

export default React.memo(DeleteAppInstanceAction);

DeleteAppInstanceAction.propTypes = {
  trigger: PropTypes.node,
  open: PropTypes.bool,
  selected: PropTypes.object,
  handleCloseDelete: PropTypes.func,
  handleSubmitDelete: PropTypes.func,
};
