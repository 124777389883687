import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { getListAppInstanceQuery } from '../apis';
import { setFieldAppInstance } from '..';
import { deepClone } from '@tenant/utility/helper/Utils';
import { useDispatch } from 'react-redux';
import queryKeyAppInstance from '../constants/queryKeyAppInstance';

const useGetListAppInstance = ({ pageSize, pageIndex, searchTerm }) => {
  const dispatch = useDispatch();

  const getListAppInstance = useQuery({
    queryKey: queryKeyAppInstance.getList(
      JSON.stringify({ pageSize, pageIndex }),
    ),
    queryFn: () => getListAppInstanceQuery({ pageSize, pageIndex, searchTerm }),
  });

  useEffect(() => {
    dispatch(
      setFieldAppInstance({
        field: 'filter',
        value: { pageSize, pageIndex, searchTerm },
      }),
    );
  }, [pageSize, pageIndex, searchTerm]);

  useEffect(() => {
    const data =
      typeof getListAppInstance !== 'undefined'
        ? getListAppInstance?.data?.data
        : [];

    if (data) {
      dispatch(
        setFieldAppInstance({
          field: 'list',
          value: deepClone(data),
        }),
      );
    }
  }, [getListAppInstance?.data, dispatch]);

  return getListAppInstance;
};

export default useGetListAppInstance;
