import React, { useEffect } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import AppGridContainer from '@tenant/core/AppGridContainer';
import AppInputImage from '@tenant/core/AppInputImage';
import {
  convertToDecimal,
  getBlobFileImage,
} from 'pages/publishApp/helpers/common';
import CategorySelect from '@tenant/core/App/Select/CategorySelect';
import {
  AUTHENTICATION_TYPE_ENUM,
  PLATFORM_ENUM,
  PRICE_TYPE_ENUM,
  APP_TYPE_ENUM,
  LAUNCH_TYPE_ENUM,
} from '@tenant/utility/constants/enum';
import AppCKEditor from '@tenant/core/AppCKEditor';
import { useAuthUser } from '@tenant/utility/AuthHooks';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';

const InformationStep = ({
  values,
  errors,
  setFieldValue,
  setCurrentValues,
}) => {
  const { user } = useAuthUser();

  useEffect(() => {
    if (user) {
      setFieldValue('AuthorName', user.name);
      setFieldValue('AuthorEmail', user.username);
    }
  }, []);

  const resetMobileFieldsIfNeeded = (platform) => {
    const isSupportMobile = [
      PLATFORM_ENUM['Mobile'],
      PLATFORM_ENUM['Cross_Platform'],
    ].includes(platform);

    const resetMobileFields = () => {
      setFieldValue('AppConnectivityState', '');
      setFieldValue('BundleZipFile', null);
      setFieldValue('BundleZipBlobReference', '');
      setFieldValue('MobileFeatureUrl', '');
      setFieldValue('MobileAppUrl', '');
    };

    if (!isSupportMobile) {
      resetMobileFields();
      return;
    }
  };

  const checkIsModuleMode = (integrationMode) => {
    return integrationMode?.toString() === APP_TYPE_ENUM['Module'].toString();
  };

  const checkIsSupportMobile = (platform) => {
    const isSupportMobile =
      platform === PLATFORM_ENUM['Mobile'];
    return isSupportMobile;
  };

  return (
    <AppGridContainer>
      <Grid item xs={12} sm={6} md={6}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Name
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <TextField
          placeholder='Enter text'
          name='Name'
          error={!!errors.Name}
          helperText={errors.Name}
          value={values.Name ?? ''}
          onChange={(e) => {
            setFieldValue('Name', e.target.value);
          }}
          onBlur={(e) => {
            setFieldValue('Name', values.Name?.trim());
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Platform
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <FormControl fullWidth error={Boolean(errors.Platform)}>
          <Select
            value={values.Platform ?? PLATFORM_ENUM['Web']}
            onChange={(e) => {
              setFieldValue('Platform', e.target.value);
              setCurrentValues({ ...values, Platform: e.target.value });

              resetMobileFieldsIfNeeded(e.target.value);
            }}
          >
            {Object.entries(PLATFORM_ENUM)
              .map(([value, key]) => ({
                key: key,
                value: value,
              }))
              .map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  {item.value}
                </MenuItem>
              ))}
          </Select>
          {Boolean(errors.Platform) && (
            <FormHelperText>{errors.Platform}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Web Integration Mode
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <FormControl
          fullWidth
          error={Boolean(errors.IntegrationMode)}
          disabled={checkIsSupportMobile(values.Platform)}
        >
          <Select
            value={values.IntegrationMode}
            onChange={(e) => {
              setFieldValue('IntegrationMode', e.target.value);
              setCurrentValues({ ...values, IntegrationMode: e.target.value });
              if (!checkIsModuleMode(e.target.value)) {
                setFieldValue('ModuleId', '');
              }

              if (checkIsModuleMode(e.target.value)) {
                setFieldValue('LaunchType', LAUNCH_TYPE_ENUM['External']);
              }
            }}
          >
            {Object.entries(APP_TYPE_ENUM)
              .map(([value, key]) => ({
                key: key,
                value: value,
              }))
              .map((item, index) => (
                <MenuItem key={index} value={item.key}>
                  {item.value}
                </MenuItem>
              ))}
          </Select>
          {Boolean(errors.IntegrationMode) && (
            <FormHelperText>{errors.IntegrationMode}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Module Id
          {checkIsModuleMode(values.IntegrationMode) && (
            <Box component='span' ml={1} sx={{ color: 'error.main' }}>
              *
            </Box>
          )}
        </Box>
        <FormControl fullWidth error={Boolean(errors.ModuleId)}>
          <TextField
            placeholder='Enter number'
            type='number'
            name='ModuleId'
            disabled={!checkIsModuleMode(values.IntegrationMode)}
            error={!!errors.ModuleId}
            helperText={errors.ModuleId}
            value={values.ModuleId ?? ''}
            sx={{ '& .Mui-disabled': { background: grey[50] } }}
            onChange={async (e) => {
              setFieldValue('ModuleId', e.target.value);
            }}
            onBlur={(e) => {
              setFieldValue('ModuleId', values.ModuleId?.trim());
            }}
            fullWidth
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          App Url
        </Box>
        <TextField
          placeholder='Enter text'
          name='AppUrl'
          error={!!errors.AppUrl}
          helperText={errors.AppUrl}
          value={values.AppUrl ?? ''}
          onChange={async (e) => {
            setFieldValue('AppUrl', e.target.value);
          }}
          onBlur={(e) => {
            setFieldValue('AppUrl', values.AppUrl?.trim());
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Key Labels{' '}
        </Box>
        <TextField
          placeholder='Enter text'
          error={!!errors.KeyLabels}
          helperText={errors.KeyLabels}
          value={values.KeyLabels ?? ''}
          onChange={(e) => {
            setFieldValue('KeyLabels', e.target.value);
          }}
          onBlur={(e) => {
            setFieldValue('KeyLabels', values.KeyLabels?.trim());
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Categories
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <FormControl fullWidth>
          <CategorySelect
            value={values.CategoryIds ?? []}
            error={!!errors.CategoryIds}
            helperText={errors.CategoryIds}
            onChange={(e, value) => {
              setFieldValue('CategoryIds', value);
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Dependencies
        </Box>
        <TextField
          placeholder='Enter text'
          error={!!errors.Dependencies}
          helperText={errors.Dependencies}
          value={values.Dependencies ?? ''}
          onChange={async (e) => {
            setFieldValue('Dependencies', e.target.value);
          }}
          onBlur={(e) => {
            setFieldValue('Dependencies', values.Dependencies?.trim());
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Authentication Type
        </Box>
        <Select
          fullWidth
          value={values.AuthenticationType}
          onChange={(e) => {
            setFieldValue('AuthenticationType', e.target.value);
          }}
        >
          {Object.entries(AUTHENTICATION_TYPE_ENUM)
            .map(([value, key]) => ({
              key: key,
              value: value,
            }))
            .map((item, index) => (
              <MenuItem key={index} value={item.key}>
                {item.value}
              </MenuItem>
            ))}
        </Select>
        {Boolean(errors.AuthenticationType) && (
          <FormHelperText>{errors.AuthenticationType}</FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Author Name
          {!!values.AuthorEmail && (
            <Box component='span' ml={1} sx={{ color: 'error.main' }}>
              *
            </Box>
          )}
        </Box>
        <TextField
          placeholder='Enter text'
          error={!!errors.AuthorName}
          helperText={errors.AuthorName}
          value={values.AuthorName ?? ''}
          onChange={async (e) => {
            setFieldValue('AuthorName', e.target.value);
          }}
          onBlur={(e) => {
            setFieldValue('AuthorName', values.AuthorName?.trim());
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Author Email{' '}
          {!!values.AuthorName && (
            <Box component='span' ml={1} sx={{ color: 'error.main' }}>
              *
            </Box>
          )}
        </Box>
        <TextField
          placeholder='Enter text'
          error={!!errors.AuthorEmail}
          helperText={errors.AuthorEmail}
          value={values.AuthorEmail ?? ''}
          onChange={async (e) => {
            setFieldValue('AuthorEmail', e.target.value);
          }}
          onBlur={(e) => {
            setFieldValue('AuthorEmail', values.AuthorEmail?.trim());
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Price Type
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <Select
          fullWidth
          value={values.PriceType}
          onChange={(e) => {
            setFieldValue('PriceType', e.target.value);
          }}
        >
          {Object.entries(PRICE_TYPE_ENUM)
            .map(([value, key]) => ({
              key: key,
              value: value,
            }))
            .map((item, index) => (
              <MenuItem key={index} value={item.key}>
                {item.value}
              </MenuItem>
            ))}
        </Select>
        {Boolean(errors.PriceType) && (
          <FormHelperText>{errors.PriceType}</FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Launch Type
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <FormControl disabled={checkIsModuleMode(values.IntegrationMode)}>
          <RadioGroup row>
            <FormControlLabel
              checked={values.LaunchType === LAUNCH_TYPE_ENUM['Internal']}
              value={LAUNCH_TYPE_ENUM['Internal']}
              control={<Radio />}
              label='Internal'
              onChange={() =>
                setFieldValue('LaunchType', LAUNCH_TYPE_ENUM['Internal'])
              }
            />
            <FormControlLabel
              checked={values.LaunchType === LAUNCH_TYPE_ENUM['External']}
              value={LAUNCH_TYPE_ENUM['External']}
              control={<Radio />}
              label='External'
              onChange={() =>
                setFieldValue('LaunchType', LAUNCH_TYPE_ENUM['External'])
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {values.PriceType === PRICE_TYPE_ENUM['SUBSCRIPTION'] && (
        <>
          <Grid item xs={12} sm={12} md={4}>
            <Box component='p' color='text.primary' fontSize={14} mb={2}>
              Monthly Price
              <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                *
              </Box>
            </Box>
            <TextField
              fullWidth
              value={values.MonthlyPrice ?? ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>£</InputAdornment>
                ),
              }}
              error={!!errors.MonthlyPrice}
              helperText={errors.MonthlyPrice}
              onChange={(e) => {
                const value = e.target.value;
                setFieldValue('MonthlyPrice', convertToDecimal(value));
              }}
              onBlur={(e) => {
                const value = e.target.value;
                setFieldValue(
                  'MonthlyPrice',
                  value ? parseFloat(value).toFixed(2) : '',
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box component='p' color='text.primary' fontSize={14} mb={2}>
              Annual Price
              <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                *
              </Box>
            </Box>
            <TextField
              fullWidth
              value={values.AnnuallyPrice ?? ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>£</InputAdornment>
                ),
              }}
              error={!!errors.AnnuallyPrice}
              helperText={errors.AnnuallyPrice}
              onChange={(e) => {
                const value = e.target.value;
                setFieldValue('AnnuallyPrice', convertToDecimal(value));
              }}
              onBlur={(e) => {
                const value = e.target.value;
                setFieldValue(
                  'AnnuallyPrice',
                  value ? parseFloat(value).toFixed(2) : '',
                );
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={12} md={12}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Icon
        </Box>
        <AppInputImage
          defaultFile={values.IconFile}
          appUrl={values.IconBlobReference}
          error={!!errors.IconBlobReference}
          helperText={errors.IconBlobReference}
          onChange={(file) => {
            if (file) {
              getBlobFileImage(file, (blob) => {
                setFieldValue('IconBlobReference', blob);
              });
            } else {
              setFieldValue('IconBlobReference', null);
            }
            setFieldValue('IconFile', file);
          }}
        />
      </Grid>
      {/* Show input selection .zip file incase LaunchType is Mobile*/}

      <Grid item xs={12} sm={12} md={12}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Short description
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <TextField
          multiline
          rows={4}
          placeholder='Enter text'
          name='ShortDescription'
          error={!!errors.ShortDescription}
          helperText={errors.ShortDescription}
          value={values.ShortDescription ?? ''}
          onChange={async (e) => {
            setFieldValue('ShortDescription', e.target.value);
          }}
          onBlur={(e) => {
            setFieldValue('ShortDescription', values.ShortDescription?.trim());
          }}
          fullWidth
          minRows={4}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Box component='p' color='text.primary' fontSize={14} mb={2}>
          Long Description
          <Box component='span' ml={1} sx={{ color: 'error.main' }}>
            *
          </Box>
        </Box>
        <Box sx={{ '& .ck-editor__editable': { minHeight: 350 } }}>
          <AppCKEditor
            initialData={values?.LongDescription}
            data={values?.LongDescription}
            error={!!errors.LongDescription}
            helperText={errors.LongDescription}
            handleChange={(event, editor) => {
              const data = editor.getData();
              setFieldValue('LongDescription', data);
            }}
          />
        </Box>
      </Grid>
    </AppGridContainer>
  );
};

export default React.memo(InformationStep);

InformationStep.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  setCurrentValues: PropTypes.func,
};
