import * as Yup from 'yup';
import checkIsRequireTargetPort from '../helpers/checkIsRequireTargetPort';

const dockerImageNameRegex =
  /^([a-z0-9]+(?:[._-][a-z0-9]+)*)(:[a-z0-9]+(?:[._-][a-z0-9]+)*)?$/;
const branchNameRegex = /^(?!.*[ ]).+(?![./])[^~^:?*[\]]*$/;
const repositoryUrlRegex =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/[^\s]*)?$/;

export default Yup.object().shape({
  BranchName: Yup.string()
    .matches(branchNameRegex)
    .max(255)
    .required()
    .label('Branch Name'),
  RepositoryUrl: Yup.string()
    .matches(repositoryUrlRegex)
    .max(255)
    .required()
    .label('Repository Url'),
  DockerfilePath: Yup.string().max(255).required().label('Dockerfile Path'),
  AccessToken: Yup.string().max(255).required().label('Access Token'),
  ImageName: Yup.string()
    .matches(dockerImageNameRegex)
    .max(255)
    .required()
    .label('Image Name'),
  RequiredEnviromentVariables: Yup.string()
    .required()
    .label('Required Environment Variables'),
  DeploymentType: Yup.object().required().label('Deployment Type'),
  TargetPort: Yup.string()
    .when('DeploymentType', {
      is: (val) => checkIsRequireTargetPort(val),
      then: Yup.string().required(),
      otherwise: Yup.string(),
    })
    .label('Target Port'),
  ShowPassword: Yup.boolean(),
});
