const apiQuery = async ({ apiFnc, onSuccess, onError, onFinally, onStart }) => {
  try {
    onStart?.();
    if (typeof apiFnc !== 'function') {
      return;
    }
    const { data } = await apiFnc();
    onSuccess?.(data);
  } catch (e) {
    console.error(e);
    onError?.(e);
  } finally {
    onFinally?.();
  }
};

export default apiQuery;
