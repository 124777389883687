import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppTooltip from '@tenant/core/AppTooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IntlMessages from '@tenant/utility/IntlMessages';
import { ROUTE_STRING } from 'constants/RouteString';
import { Formik } from 'formik';
import validationSchema from '../../redux/slices/dockerImage/constants/validationSchema';
import DockerAppTypes from 'constants/DockerAppTypes';
import useAddDockerImage from 'redux/slices/dockerImage/hooks/useAddDockerImage';
import AddDockerImageForm from './components/Common/AddDockerImageForm';

const Add = () => {
  const navigate = useNavigate();
  const onClickBackButton = () => {
    navigate(ROUTE_STRING.DOCKER_IMAGE.LIST);
  };
  const addItem = useAddDockerImage();

  const handleSubmit = (data) => {
    addItem.mutate(
      {
        ...data,
        DeploymentType: data.DeploymentType?.Value,
        TargetPort: +data.TargetPort,
        RequiredEnviromentVariables: data.RequiredEnviromentVariables.split(
          /\r?\n/,
        )
          .map((line) => line.trim())
          .join(','),
      },
      {
        onSuccess(data) {
          navigate(
            ROUTE_STRING.DOCKER_IMAGE.VIEW.replace(
              ':repository',
              data?.data?.ImageName,
            ),
          );
        },
      },
    );
  };

  return (
    <>
      <AppsHeader>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              gap: 4,
            }}
            component='span'
            mr={{ xs: 2, sm: 4 }}
          >
            <AppTooltip title={<IntlMessages id='common.back' />}>
              <ArrowBackIcon
                sx={{
                  color: 'text.secondary',
                }}
                onClick={onClickBackButton}
              />
            </AppTooltip>
          </Box>
        </Box>
      </AppsHeader>

      <Box p={4}>
        <Formik
          enableReinitialize
          validateOnChange={true}
          validateOnBlur={false}
          initialValues={{ DeploymentType: DockerAppTypes[0] }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ submitForm, values, setFieldValue, validateField, errors }) => (
            <AddDockerImageForm
              validateField={validateField}
              setForm={setFieldValue}
              submitForm={submitForm}
              onCancel={onClickBackButton}
              loading={addItem.isPending}
              values={values}
              errors={errors}
            />
          )}
        </Formik>
      </Box>
    </>
  );
};

export default React.memo(Add);
