import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { updateCronAppQuery } from '../apis';
import { toast } from 'react-toastify';

const useUpdateCron = () => {
  const mutationData = useMutation({
    mutationFn: updateCronAppQuery,
    onSuccess: (data) => {
      toast.success('Update Cron successfully');
    },
    onError: (error) => {
      const message = error?.response?.data?.message ?? 'Server error';
      toast.error(message);
      console.error('Error update cron:', error);
    },
  });

  return mutationData;
};

export default useUpdateCron;
