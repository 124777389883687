import React, { useMemo } from 'react';
import { ROUTE_STRING } from 'constants/RouteString';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useEditApp from '../hooks/useEditApp';
import Steps from 'pages/publishApp/components/Steps';
import useAppDetailQuery from '../hooks/useAppDetailQuery';
import { initialValuesStepInformation } from 'pages/publishApp/helpers/initialValues';
import AppLoader from '@tenant/core/AppLoader';
import startCase from 'lodash/startCase';
import { ACTION_MODE_ENUM } from 'constants/AppEnums';
import { APP_CONNECTIVITY_STATE_ENUM } from '@tenant/utility/constants/enum';
import { toast } from 'react-toastify';

const EditApp = () => {
  const { appId } = useParams();
  const { data } = useAppDetailQuery({ appId });
  const navigate = useNavigate();
  const { mutate, isPending, isSuccess } = useEditApp();

  const initialValues = useMemo(() => {
    const reverseTransformMetaData = (data) => {
      return data.map((entry) => {
        return {
          ...entry,
          slots: Object.keys(entry.slots).map((slotType) => {
            return {
              type: slotType,
              label: startCase(slotType),
              features: entry.slots[slotType].map((feature) => ({
                ...feature,
              })),
            };
          }),
        };
      });
    };

    return (
      {
        ...data,
        CategoryIds: [
          ...new Map(
            (data?.Categories ?? []).map((item) => [item.Id, item]),
          ).values(),
        ],
        MobileBAUModuleIDs: [
          ...new Set((data?.MobileBAUModules ?? []).map((item) => item.Id)),
        ],
        MobileFeatureUrl:
          data?.AppConnectivityState?.toString() ===
          APP_CONNECTIVITY_STATE_ENUM['Online'].toString()
            ? data?.MobileAppUrl ?? ''
            : '',
        LaunchType: data?.LaunchedType ?? null,
        AppType: data?.AppType ?? 1,
        AppVersionNumber: data?.AppVersion,
        AppId: data?.Id,
        PageSlots: reverseTransformMetaData(JSON.parse(data?.MetaData ?? '[]')),
        AnnuallyPrice: data?.AnnuallyPrice ?? '',
        MonthlyPrice: data?.MonthlyPrice ?? '',
        KeyLabels: data?.KeyLabels ?? '',
      } || initialValuesStepInformation
    );
  }, [data]);

  const handleSubmit = (data) => {
    mutate(data, {
      onSuccess: () => {
        navigate(ROUTE_STRING.LIST_APP.LIST + '/' + data.Id);
      },
      onError: (error) => {
        const message = error?.message ?? 'Server Error';
        toast.error(message);
      },
    });
  };

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 4 }}>
        <Typography color='text.primary'>Developer</Typography>
        <Typography color='text.primary'>
          <Link to={ROUTE_STRING.LIST_APP.LIST}>Apps</Link>
        </Typography>
        {appId && (
          <Typography color='text.primary'>
            <Link to={ROUTE_STRING.LIST_APP.LIST + '/' + appId}>Detail</Link>
          </Typography>
        )}
        <Typography color='text.primary'>Edit</Typography>
      </Breadcrumbs>
      {isPending && <AppLoader />}
      <Box
        sx={(theme) => ({
          borderRadius: 3,
          p: 4,
          background: theme.palette.background.paper,
          height: '100%',
        })}
      >
        <Steps
          mode={ACTION_MODE_ENUM['Edit']}
          input={initialValues}
          submit={handleSubmit}
          isPending={isPending}
          isSuccess={isSuccess}
        />
      </Box>
    </>
  );
};

export default React.memo(EditApp);
