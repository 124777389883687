import slice from './slice';

const appInstanceReducer = slice.reducer;

export const {
  setFieldAppInstance,
  addItemAppInstance,
  removeItemAppInstance,
} = slice.actions;

export default appInstanceReducer;
