import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addAppPackageQuery } from '../apis';
import { addItemAppPackage } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import queryKeyAppPackage from '../constants/queryKeyAppPackage';

const useAddAppPackage = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { filter } = useSelector((s) => s.appPackage);

  const addAppPackage = useMutation({
    mutationFn: addAppPackageQuery,
    onSuccess: (data) => {
      toast.success('Add App Package successfully');
      dispatch(addItemAppPackage(data?.data));
      queryClient.invalidateQueries([
        queryKeyAppPackage.getList(JSON.stringify(filter ?? {})),
      ]);
    },
    onError: (error) => {
      const message = error?.response?.data?.message ?? 'Server error';
      toast.error(message);
      console.error('Error add app package:', error);
    },
  });

  return addAppPackage;
};

export default useAddAppPackage;
