import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useModal from '@tenant/utility/hooks/useModal';
import { Box } from '@mui/material';
import DeleteDialog from '@tenant/core/App/Dialog/DeleteDialog';
import useDeleteAppPackage from 'redux/slices/appPackage/hooks/useDeleteAppPackage';
import { removeItem } from 'redux/slices/appPackage';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import queryKeyAppPackage from 'redux/slices/appPackage/constants/queryKeyAppPackage';

const DeleteAppPackageAction = ({
  trigger,
  open,
  selected,
  handleCloseDelete = () => {},
  handleSubmitDelete = () => {},
}) => {
  const { filter } = useSelector((s) => s.appPackage);
  const queryClient = useQueryClient();
  const deleteMutate = useDeleteAppPackage();
  const { visible, onShow, onClose } = useModal();
  const visibleDelete = typeof open !== 'undefined' ? open : visible;

  const handleClose = () => {
    onClose();
    handleCloseDelete();
  };

  const handleDelete = () => {
    deleteMutate.mutate(selected, {
      onSuccess() {
        toast.success('Delete App Package successfully');
        onClose();
        handleSubmitDelete();
        queryClient.refetchQueries(
          queryKeyAppPackage.getList(JSON.stringify(filter ?? {})),
        );
      },
    });
  };

  return (
    <>
      <Box component='span' onClick={onShow}>
        {trigger}
      </Box>
      <DeleteDialog
        visible={visibleDelete}
        loading={deleteMutate.isPending}
        name={selected?.Name}
        handleClose={handleClose}
        handleSave={handleDelete}
      />
    </>
  );
};

export default React.memo(DeleteAppPackageAction);

DeleteAppPackageAction.propTypes = {
  trigger: PropTypes.node,
  open: PropTypes.bool,
  selected: PropTypes.object,
  handleCloseDelete: PropTypes.func,
  handleSubmitDelete: PropTypes.func,
};
