import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Chip, Pagination, Tooltip, Typography } from '@mui/material';
import useAppsQuery from '../hooks/useAppsQuery';
import { NavLink } from 'react-router-dom';
import { ROUTE_STRING } from 'constants/RouteString';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import { PAGE_SIZE } from 'constants/Common';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppSearch from '@tenant/core/AppSearchBar';
import { useDebounce } from '@tenant/utility/hooks';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HandymanIcon from '@mui/icons-material/Handyman';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { StatusAppType } from 'constants/AppEnums';

export const StatusApp = {
  [StatusAppType.IN_ACTIVE]: {
    label: 'Inactive',
    color: 'error',
    icon: <CancelIcon />,
  },
  [StatusAppType.ACTIVE]: {
    label: 'Active',
    color: 'info',
    icon: <RocketLaunchIcon />,
  },
  [StatusAppType.COMING_SOON]: {
    label: 'Coming Soon',
    color: 'warning',
    icon: <HourglassEmptyIcon />,
  },
  [StatusAppType.DRAFT]: {
    label: 'Draft',
    color: 'warning',
    icon: <HandymanIcon />,
  },
};

const Table = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { data, isLoading } = useAppsQuery({
    searchTerm: debouncedSearchTerm,
    pageIndex,
    pageSize: PAGE_SIZE,
  });

  const clearSearchTerm = () => {
    setSearchTerm('');
  };

  const onChangeSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const columns = [
    {
      field: 'IconBlobReference',
      headerName: 'Logo',
      width: 75,
      sortable: false,
      renderCell: (params) => (
        <Box
          component='img'
          sx={{
            height: 50,
            width: 50,
            borderRadius: '50%',
            p: 1,
          }}
          alt={params.row.name}
          src={params.value}
        />
      ),
    },
    {
      field: 'Name',
      headerName: 'Name',
      width: 300,
      sortable: false,
      renderCell: (params) => (
        <NavLink
          className='MuiDataGrid-cellContent'
          to={`${ROUTE_STRING.LIST_APP.LIST}/${params.row.Id}`}
          style={{ textDecoration: 'none' }}
        >
          {params.value}
        </NavLink>
      ),
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <Chip
          label={StatusApp[params.value].label}
          color={StatusApp[params.value].color}
          icon={StatusApp[params.value].icon}
          size='small'
          sx={{ px: 3, width: 150 }}
        />
      ),
    },
    {
      field: 'ShortDescription',
      headerName: 'Short Description',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Box
            component={'span'}
            sx={{ width: '100%', display: 'block' }}
            className='MuiDataGrid-cellContent'
          >
            {params.value}
          </Box>
        </Tooltip>
      ),
    },
  ];

  const rows = data?.Items || [];
  const onPageChange = (value) => {
    setPageIndex(value);
  };

  return (
    <>
      <AppsHeader>
        <Box
          flex={1}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <AppSearch
              isClearable
              searchValue={searchTerm}
              onClearSearch={clearSearchTerm}
              iconPosition='right'
              id='outlined-adornment-weight'
              size='small'
              overlap={false}
              onChange={onChangeSearch}
            ></AppSearch>
          </Box>
        </Box>
      </AppsHeader>
      <Box style={{ height: 'calc(100vh - 270px)', width: '100%' }}>
        <DataGrid
          hideFooter
          disableColumnSelector
          disableColumnMenu
          rows={rows}
          columns={columns}
          getRowId={(row) => row.Id}
          loading={isLoading}
        />
      </Box>

      <FooterWrapper className='footer'>
        <Box justifyContent={'center'} className='footerContainer'>
          <Pagination
            count={Math.ceil(data?.TotalCount / PAGE_SIZE) || 0}
            page={pageIndex}
            onChange={(e, v) => onPageChange(v)}
            showFirstButton
            showLastButton
          />
          <Typography>Total: {data?.TotalCount || 0}</Typography>
        </Box>
      </FooterWrapper>
    </>
  );
};

export default Table;
