import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addDockerImageQuery } from '../apis';
import { addItem } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import queryKeyDockerImage from '../constants/queryKeyDockerImage';

const useAddDockerImage = (isUpgrade = false) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { filter } = useSelector((s) => s.dockerImage);

  const addDockerImage = useMutation({
    mutationFn: addDockerImageQuery,
    onSuccess: (data) => {
      toast.success(
        isUpgrade
          ? 'The Docker image has been deployment successfully and is now running in the background.You can continue to use your tasks while the container processes requests its functions.'
          : 'The Docker image has been add successfully and is now running in the background.You can continue to use your tasks while the container processes requests its functions.',
      );
      !isUpgrade && dispatch(addItem(data?.data));

      queryClient.invalidateQueries([
        queryKeyDockerImage.getList(JSON.stringify(filter ?? {})),
      ]);
    },
    onError: (error) => {
      const message = error?.response?.data?.message ?? 'Server error';
      toast.error(message);
      console.error('Error creating user:', error);
    },
  });

  return addDockerImage;
};

export default useAddDockerImage;
