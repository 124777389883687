import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ReactDOM from 'react-dom';

const DeleteDialogElement = React.memo(
  ({
    visible,
    handleClose,
    handleSave,
    loading,
    name = '',
    action = 'Delete',
  }) => (
    <Dialog open={visible} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle fontSize={16}>
        Confirm {action} {name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-delete-dialog-description'>
          Warning: This action cannot be undone. Are you sure you want to
          {' ' + action.toLowerCase() + ' '}
          this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant='contained'
          color='error'
          onClick={handleSave}
        >
          {action}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  ),
);

const DeleteDialog = ({
  visible,
  handleClose,
  handleSave,
  loading,
  name,
  action = 'Delete',
  isPortal = true,
}) => {
  return isPortal ? (
    ReactDOM.createPortal(
      <DeleteDialogElement
        visible={visible}
        loading={loading}
        name={name}
        action={action}
        handleClose={handleClose}
        handleSave={handleSave}
      />,
      document.body,
    )
  ) : (
    <DeleteDialogElement
      visible={visible}
      loading={loading}
      name={name}
      action={action}
      handleClose={handleClose}
      handleSave={handleSave}
    />
  );
};

export default React.memo(DeleteDialog);

DeleteDialogElement.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  name: PropTypes.string,
  action: PropTypes.string,
};

DeleteDialog.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  isPortal: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  name: PropTypes.string,
  action: PropTypes.string,
};
