import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { updateClientsByAppInstance } from '../apis';

const useUpdateClientByAppInstance = () => {
  const mutationAction = useMutation({
    mutationFn: updateClientsByAppInstance,
    onSuccess: (data) => {
      toast.success('Update Idp Client successfully');
    },
    onError: (error) => {
      const message = error?.response?.data?.message ?? 'Server error';
      toast.error(message);
      console.error('Error Update Idp Client:', error);
    },
  });

  return mutationAction;
};

export default useUpdateClientByAppInstance;
