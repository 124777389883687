import { Box, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as NoItem } from 'assets/icon/no-item.svg';

const AppJLEmptyResult = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '200px',
      }}
    >
      <NoItem />

      <Typography sx={{ marginTop: 2 }} variant='caption'>
        No item displayed
      </Typography>
    </Box>
  );
};

export default React.memo(AppJLEmptyResult);

AppJLEmptyResult.propTypes = {};
