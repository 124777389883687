import * as Yup from 'yup';

export const cronRegex =
  /^([0-5]?[0-9]|\*|([0-5]?[0-9]\/[1-5]?[0-9])|([0-9]|1[0-9]|2[0-3]|[*/])|(0?[1-9]|[12][0-9]|3[01]|\*)|(0?[1-9]|1[0-2]|[A-Za-z]{3}|\*)|(0?[0-6]|[A-Za-z]{3}|\*))$/;

export default Yup.object().shape({
  Name: Yup.string().max(255).required().label('Name'),
  CpuAndMemory: Yup.number().required().label('Cpu And Memory'),
  AppPackage: Yup.object().required().label('App Package'),
});
