import jwtAxios from '@tenant/services/auth/jwt-auth';

export const DOCKER_IMAGE_APIS = {
  ROOT: 'api/docker-images',
  GROUP_BY_NAME: 'api/docker-images/group-by-name',
  REPOSITORY_AVAILABLE: 'api/docker-repositories/:repository/exists',
};

export const getListDockerImageQuery = (params) => {
  return jwtAxios.get(DOCKER_IMAGE_APIS.ROOT, { params });
};

export const getDetailDockerImageQuery = (repository) => {
  return jwtAxios.get(DOCKER_IMAGE_APIS.ROOT + '/' + repository);
};

export const getAvailableDockerImageQuery = (repository) => {
  return (
    repository &&
    jwtAxios.get(
      DOCKER_IMAGE_APIS.REPOSITORY_AVAILABLE.replace(':repository', repository),
    )
  );
};

export const getListGroupByNameDockerImageQuery = () => {
  return jwtAxios.get(DOCKER_IMAGE_APIS.GROUP_BY_NAME);
};

export const addDockerImageQuery = (payload) => {
  return jwtAxios.post(DOCKER_IMAGE_APIS.ROOT, payload);
};

export const deleteDockerImageQuery = (payload) => {
  return jwtAxios.delete(DOCKER_IMAGE_APIS.ROOT + '/' + payload?.Name);
};
