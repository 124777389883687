import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';
import { inputFormatDateTime } from '@tenant/utility/constants/default';
import AppLoader from '@tenant/core/AppLoader';
import AppJLEmptyResult from '@tenant/core/AppList/AppJLEmptyResult';
import SimpleBar from 'simplebar-react';

const LogList = ({ data = [], loading = false }) => {
  return (
    <SimpleBar style={{ height: 'calc(100vh - 275px)' }}>
      <Box
        sx={{
          '& .MuiAccordion-root': {
            boxShadow: 'none',
            backgroundColor: grey[50],
          },
        }}
      >
        {loading && <AppLoader />}
        {data.length === 0 && <AppJLEmptyResult />}
        {data.map((data, i) => (
          <Accordion key={data.CreatedDate + i} defaultExpanded={i === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ color: 'text.secondary' }}>
                {formatDateFromISO(data.CreatedDate, inputFormatDateTime)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontSize={13}>{data.AppLog}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </SimpleBar>
  );
};

export default React.memo(LogList);

LogList.propTypes = {};
