import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { getListAppPackageQuery } from '../apis';
import { setFieldAppPackage } from '..';
import { deepClone } from '@tenant/utility/helper/Utils';
import { useDispatch } from 'react-redux';
import queryKeyAppPackage from '../constants/queryKeyAppPackage';

const useGetListAppPackage = ({ searchTerm, pageIndex, pageSize }) => {
  const dispatch = useDispatch();

  const getListAppPackage = useQuery({
    queryKey: queryKeyAppPackage.getList(
      JSON.stringify({ pageIndex, pageSize }),
    ),
    queryFn: () => getListAppPackageQuery({ searchTerm, pageIndex, pageSize }),
  });

  useEffect(() => {
    dispatch(
      setFieldAppPackage({
        field: 'filter',
        value: { searchTerm, pageIndex, pageSize },
      }),
    );
  }, [searchTerm, pageIndex, pageSize, dispatch]);
  useEffect(() => {
    const data =
      typeof getListAppPackage !== 'undefined' ? getListAppPackage?.data : [];
    dispatch(
      setFieldAppPackage({
        field: 'filter',
        value: { searchTerm, pageIndex, pageSize },
      }),
    );
    if (data) {
      dispatch(
        setFieldAppPackage({ field: 'list', value: deepClone(data?.data) }),
      );
    }
  }, [getListAppPackage?.data, searchTerm, pageIndex, pageSize, dispatch]);

  return getListAppPackage;
};

export default useGetListAppPackage;
