import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  dividerClasses,
  Grid,
  IconButton,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppTooltip from '@tenant/core/AppTooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IntlMessages from '@tenant/utility/IntlMessages';
import { ROUTE_STRING } from 'constants/RouteString';
import DockerAppTypes from 'constants/DockerAppTypes';
import { grey } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { setFieldAppPackage } from 'redux/slices/appPackage';
import { DataGrid } from '@mui/x-data-grid';
import useGetDetailAppPackage from 'redux/slices/appPackage/hooks/useGetDetailAppPackage';
import { Code } from 'react-content-loader';
import UpgradeVersionButton from './components/Common/UpgradeVersionButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SimpleBar from 'simplebar-react';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteAppPackageAction from './components/Common/DeleteAppPackageAction';
import DeleteIcon from '@mui/icons-material/Delete';
import Error404 from 'pages/errorPages/Error404';

const Detail = () => {
  const { id } = useParams();
  const { data, isLoading, refetch, isRefetching, isError } =
    useGetDetailAppPackage(id);
  const loading = isLoading || isRefetching;
  const item = data?.data ?? {};
  const navigate = useNavigate();
  const onClickBackButton = () => {
    navigate(ROUTE_STRING.APP_PACKAGE.LIST);
  };

  const columns = [
    {
      field: 'Name',
      headerName: 'Name',
      width: 250,
      sortable: true,
    },
    {
      field: 'RequiredEnviromentVariables',
      headerName: 'Required Environment Variables',
      width: 350,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box className='MuiDataGrid-cellContent'>
          {params.value ? params.value?.split(',').join('\n') : '-'}
        </Box>
      ),
    },
    {
      field: 'TargetPort',
      headerName: 'TargetPort',
      width: 125,
      sortable: false,
      renderCell: (params) => params.value ?? '-',
    },
    {
      field: 'DeploymentType',
      headerName: 'Deployment Type',
      width: 250,
      sortable: false,
      renderCell: (params) => (
        <Box
          component={'span'}
          sx={{ width: '100%', display: 'block' }}
          className='MuiDataGrid-cellContent'
        >
          {DockerAppTypes.find((e) => e.Value === params.value)?.Description}
        </Box>
      ),
    },
  ];

  if (isError) {
    return <Error404 />;
  }

  return (
    <>
      <AppsHeader>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              gap: 4,
            }}
            component='span'
            mr={{ xs: 2, sm: 4 }}
          >
            <AppTooltip title={<IntlMessages id='common.back' />}>
              <ArrowBackIcon
                sx={{
                  color: 'text.secondary',
                }}
                onClick={onClickBackButton}
              />
            </AppTooltip>
            {loading ? (
              <Skeleton width={50} />
            ) : (
              <Typography variant='h4' sx={{ cursor: 'auto' }}>
                {item.Name}{' '}
                <IconButton title='Refresh' size='small' onClick={refetch}>
                  <RefreshIcon fontSize='inherit' />
                </IconButton>
              </Typography>
            )}
          </Box>
          {isLoading || isRefetching ? (
            <Skeleton sx={{ width: 100 }} />
          ) : (
            <Box
              display={'flex'}
              alignItems={'center'}
              sx={{
                [`& .${dividerClasses.root}`]: {
                  height: 20,
                },
              }}
            >
              {!data?.data?.IsLatest && (
                <UpgradeVersionButton
                  appPackageId={id}
                  callbackSuccess={refetch}
                />
              )}
              <Divider orientation='vertical' variant='middle' sx={{ ml: 2 }} />
              <DeleteAppPackageAction
                selected={item}
                trigger={
                  <IconButton variant='outlined' color='error' title='Delete'>
                    <DeleteIcon />
                  </IconButton>
                }
                handleSubmitDelete={onClickBackButton}
              />
            </Box>
          )}
        </Box>
      </AppsHeader>
      <SimpleBar
        style={{ height: 'calc(100vh - 225px)', marginBottom: '20px' }}
      >
        <Box p={4}>
          {!(isLoading || isRefetching) && (
            <Box>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12}>
                  <Accordion
                    defaultExpanded
                    sx={{
                      boxShadow: 'none',
                      backgroundColor: grey[50],
                      pl: 5,
                      pr: 5,
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant='h4'> Information </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={5}>
                        <Grid item xs={12} sm={9}>
                          <ListItemText
                            primary='Name'
                            secondary={item.Name ?? '-'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <ListItemText
                            primary='Version'
                            secondary={item.Version ?? ''}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <ListItemText
                            primary='Description'
                            secondary={item.Description ?? '-'}
                            secondaryTypographyProps={{
                              sx: {
                                whiteSpace: 'pre',
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box mb={5} mt={5}>
                    <Typography variant='h4'> App Components </Typography>
                  </Box>

                  <Box style={{ height: 450, width: '100%' }}>
                    <DataGrid
                      hideFooter
                      disableColumnSelector
                      disableColumnMenu
                      rows={item?.AppComponents ?? []}
                      columns={columns}
                      getRowId={(row) => row.Id}
                      getRowHeight={() => 'auto'}
                      sx={{
                        '& [data-field="RequiredEnviromentVariables"] .MuiDataGrid-cellContent':
                          {
                            whiteSpace: 'pre',
                            pt: 3,
                            pb: 3,
                          },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}

          {(isLoading || isRefetching) && <Code />}
        </Box>
      </SimpleBar>
    </>
  );
};

export default React.memo(Detail);
