import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { upgradeVersionAppPackageQuery } from '../apis';
import { addItemAppPackage } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import queryKeyAppPackage from '../constants/queryKeyAppPackage';
import { useParams } from 'react-router-dom';

const useUpgradeVersionAppPackage = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { filter } = useSelector((s) => s.appPackage);
  const { id } = useParams();

  const mutateQuery = useMutation({
    mutationFn: upgradeVersionAppPackageQuery,
    onSuccess: (data) => {
      toast.success('Deployment New Version App Package successfully');
      queryClient.invalidateQueries([queryKeyAppPackage.getDetail(id)]);
    },
    onError: (error) => {
      const message = error?.response?.data?.message ?? 'Server error';
      toast.error(message);
      console.error('Error Deployment New Version app package:', error);
    },
  });

  return mutateQuery;
};

export default useUpgradeVersionAppPackage;
