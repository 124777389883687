import jwtAxios from '@tenant/services/auth/jwt-auth';

export const APP_INSTANCE_APIS = {
  ROOT: 'api/app-instances',
  IDP_CLIENTS: 'api/app-instances/:appInstanceId/idp-clients',
};

const replaceEndpointUrl = (
  appInstanceId,
  endpoint = APP_INSTANCE_APIS.IDP_CLIENTS,
) => {
  return endpoint.replace(':appInstanceId', appInstanceId);
};

export const getListAppInstanceQuery = (params) => {
  return jwtAxios.get(APP_INSTANCE_APIS.ROOT, { params });
};

export const getDetailAppInstanceQuery = (id) => {
  return jwtAxios.get(APP_INSTANCE_APIS.ROOT + '/' + id);
};

export const addAppInstanceQuery = (payload) => {
  return jwtAxios.post(APP_INSTANCE_APIS.ROOT, payload);
};

export const deleteAppInstanceQuery = (payload) => {
  return jwtAxios.delete(APP_INSTANCE_APIS.ROOT + '/' + payload?.Id);
};

export const getListClientsByAppInstance = (appInstanceId) => {
  return jwtAxios.get(replaceEndpointUrl(appInstanceId));
};

export const addClientsByAppInstance = ({ appInstanceId, ...payload }) => {
  return jwtAxios.post(replaceEndpointUrl(appInstanceId), payload);
};

export const updateClientsByAppInstance = ({
  appInstanceId,
  idpClientId,
  ...payload
}) => {
  return jwtAxios.patch(
    replaceEndpointUrl(appInstanceId) + '/' + idpClientId,
    payload,
  );
};

export const deleteClientsByAppInstance = ({ appInstanceId, idpClientId }) => {
  return jwtAxios.delete(replaceEndpointUrl(appInstanceId) + '/' + idpClientId);
};

export const upgradeVersionAppInstanceQuery = (payload) => {
  return jwtAxios.put(
    APP_INSTANCE_APIS.ROOT +
      '/' +
      payload.appInstanceId +
      ':upgrade-to-latest-image-versions',
  );
};
