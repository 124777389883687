import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { getListDockerImageQuery } from '../apis';
import { setFieldDockerImage } from '..';
import { deepClone } from '@tenant/utility/helper/Utils';
import { useDispatch } from 'react-redux';
import serializeListDockerImageModel from '../helpers/serializeListDockerImageModel';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
} from '@tenant/utility/constants/default';
import queryKeyDockerImage from '../constants/queryKeyDockerImage';

const useGetListDockerImage = ({
  pageIndex = DEFAULT_PAGE_INDEX,
  pageSize = DEFAULT_PAGE_SIZE,
  searchTerm = '',
}) => {
  const dispatch = useDispatch();

  const getListDockerImage = useQuery({
    queryKey: queryKeyDockerImage.getList(JSON.stringify(pageIndex, pageSize)),
    queryFn: () => getListDockerImageQuery({ pageIndex, pageSize, searchTerm }),
    keepPreviousData: true,
  });

  useEffect(() => {
    dispatch(
      setFieldDockerImage({
        field: 'filter',
        value: { pageIndex, pageSize, searchTerm },
      }),
    );
  }, [pageIndex, pageSize, searchTerm, dispatch]);

  useEffect(() => {
    const data =
      typeof getListDockerImage !== 'undefined' ? getListDockerImage?.data : [];

    if (data) {
      dispatch(
        setFieldDockerImage({
          field: 'list',
          value: deepClone(data?.data),
        }),
      );
    }
  }, [getListDockerImage?.data, dispatch]);

  return getListDockerImage;
};

export default useGetListDockerImage;
