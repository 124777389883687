import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Button,
  Collapse,
  CardHeader,
  TextField,
  Grid,
  IconButton,
  CardActions,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DockerImageGroupByNameSelect from '@tenant/core/App/Select/DockerImageGroupByNameSelect';
import PropTypes from 'prop-types';
import { deepClone } from '@tenant/utility/helper/Utils';
import DockerImageSelect from '@tenant/core/App/Select/DockerImageSelect';

const generateRandomID = () => {
  return 'id-' + Date.now() + '-' + Math.floor(Math.random() * 10000);
};

const DockerImageCollapseCard = ({
  handleChange = () => {},
  isError,
  initialValues,
}) => {
  // example value: { id: '1', Name: 'newname', ImageNameItem: null, ImageName: '' },
  const [environments, setEnvironments] = useState(deepClone(initialValues));

  const checkNameValidation = (name) => {
    const regex = /^[a-z][a-z0-9]*$/;
    return regex.test(name);
  };

  const checkDockerImageValidation = (dockerImage) => {
    return !!dockerImage;
  };

  useEffect(() => {
    // handle change include params: (value, isValid)
    handleChange(
      environments.map((e) => ({
        id: e.id,
        Name: e.Name,
        ImageName: e.ImageNameItem?.Name,
        ImageNameItem: e.ImageNameItem,
      })),
      !environments.some(
        (e) =>
          !checkNameValidation(e.Name) ||
          !checkDockerImageValidation(e.ImageNameItem),
      ),
    );
  }, [environments]);

  const setFieldValue = (index, field, value) => {
    setEnvironments((prevEnvironments) =>
      prevEnvironments.map((env, i) =>
        i === index ? { ...env, [field]: value } : env,
      ),
    );
  };

  const deleteEnv = (env) => {
    setEnvironments((s) => s.filter((e) => e.id !== env.id));
  };

  const addNewRow = () => {
    setEnvironments((s) => [
      ...s,
      {
        id: 'new-item-' + generateRandomID(),
        Name: '',
        ImageName: null,
      },
    ]);
  };

  return (
    <Card
      variant='outlined'
      sx={{
        mb: 3,
        boxShadow: 'none',
        borderColor: 'transparent',
        color: isError && 'error.main',
        '& .MuiCardContent-root': {
          p: 0,
        },
        '& .MuiCardActions-root': {
          p: 0,
        },
      }}
    >
      <CardContent>
        {isError && (
          <CardActions>
            <Typography p={2} color='error'>
              {!environments?.length
                ? 'App Containers at least 1 record.'
                : 'App Containers is invalid.'}
            </Typography>
          </CardActions>
        )}
        {environments?.map((env, idx) => (
          <Grid
            mt={4}
            mb={4}
            alignItems='start'
            key={env.id}
            container
            spacing={5}
          >
            <Grid item xs={4}>
              <TextField
                fullWidth
                value={env.Name}
                label='Name'
                inputProps={{ maxLength: 255 }}
                placeholder='E.g. containername'
                error={!checkNameValidation(env.Name)}
                helperText={
                  !checkNameValidation(env.Name)
                    ? 'Name is matches with /^[a-z][a-z0-9]*$/'
                    : ''
                }
                onChange={(e) => {
                  setFieldValue(idx, 'Name', e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <DockerImageSelect
                fullWidth
                isMultiple={false}
                value={env.ImageNameItem || null}
                label='Image Name *'
                error={!checkDockerImageValidation(env.ImageNameItem)}
                helperText={
                  !checkDockerImageValidation(env.ImageNameItem)
                    ? 'Image Name is required'
                    : ''
                }
                onChange={(e, value) => {
                  setFieldValue(idx, 'ImageNameItem', value);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label='delete' onClick={() => deleteEnv(env)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button sx={{ mt: 3 }} onClick={addNewRow}>
          {' '}
          Add New Row{' '}
        </Button>
      </CardContent>
    </Card>
  );
};

export default React.memo(DockerImageCollapseCard);

DockerImageCollapseCard.propTypes = {
  handleChange: PropTypes.func,
  isError: PropTypes.bool,
  initialValues: PropTypes.array,
};
