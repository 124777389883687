import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  Typography,
} from '@mui/material';
import UpdateCronDialog from '../UpdateCronDialog';
import useModal from '@tenant/utility/hooks/useModal';
import { CONTAINER_APP_STATUS_ENUM } from '@tenant/utility/constants/enum';
import DockerAppTypes from 'constants/DockerAppTypes';
import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';
import { inputFormatDateTime } from '@tenant/utility/constants/default';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
const ContainerAppDescription = ({ detail, appInstanceId, containerAppId }) => {
  const { visible, onClose, onShow } = useModal();
  const isConsoleApp =
    !!detail?.DeploymentType &&
    DockerAppTypes.find(
      (e) => e.Value.toString() === detail.DeploymentType.toString(),
    )?.IsCron;

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
    toast.info('Text copied to clipboard');
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={6} sm={6} md={3}>
          <ListItemText
            primary='App Component Name'
            secondary={detail?.AppComponentName || '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <ListItemText
            primary='Container App Name'
            secondary={detail?.ContainerAppName || '-'}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <ListItemText primary='Image' secondary={detail?.Image || '-'} />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <ListItemText
            primary='Status'
            secondaryTypographyProps={{
              sx: {
                color:
                  detail?.Status === CONTAINER_APP_STATUS_ENUM['Running']
                    ? 'success.main'
                    : 'error.main',
                textTransform: 'uppercase',
              },
            }}
            secondary={
              detail?.Status
                ? Object.entries(CONTAINER_APP_STATUS_ENUM).find(
                    ([key, value]) => value === detail.Status,
                  )[0]
                : '-'
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <ListItemText
            primary='Deployment Type'
            secondary={
              detail?.DeploymentType
                ? DockerAppTypes.find(
                    (e) =>
                      e.Value.toString() === detail.DeploymentType.toString(),
                  ).Description
                : '-'
            }
          />
        </Grid>

        {!!detail?.DeploymentType && isConsoleApp && (
          <Grid item xs={6} sm={6} md={3}>
            <UpdateCronDialog
              visible={visible}
              handleClose={onClose}
              initialValues={{ Cron: detail?.Cron }}
              appInstanceId={appInstanceId}
              containerAppId={containerAppId}
            />
            <ListItemText
              primary='Cron'
              secondary={
                <Box
                  component='span'
                  sx={{
                    color: 'primary.main',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={onShow}
                >
                  {detail?.Cron || '-'}
                </Box>
              }
            />
          </Grid>
        )}
      </Grid>

      {!isConsoleApp && (
        <>
          <Box mt={3} mb={3}>
            <Divider />
          </Box>

          <Grid container spacing={5}>
            <Grid item xs={6} sm={6} md={6}>
              <ListItemText
                primary='Custom Domain Name'
                secondary={
                  detail?.CustomDomainName ? (
                    <>
                      <Box
                        component={'a'}
                        href={'https://' + detail?.CustomDomainName}
                        target='_blank'
                        mr={2}
                      >
                        {detail?.CustomDomainName}
                      </Box>
                      <IconButton
                        size='small'
                        title='Copy'
                        onClick={() =>
                          copyToClipboard(detail?.CustomDomainName)
                        }
                      >
                        <ContentCopyIcon fontSize='inherit' />
                      </IconButton>
                    </>
                  ) : (
                    '-'
                  )
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <ListItemText
                primary='Fully Qualified Domain Name'
                secondary={
                  detail?.FullyQualifiedDomainName ? (
                    <>
                      <Box
                        component={'a'}
                        href={'https://' + detail?.FullyQualifiedDomainName}
                        target='_blank'
                        mr={2}
                      >
                        {detail?.FullyQualifiedDomainName}
                      </Box>
                      <IconButton
                        size='small'
                        title='Copy'
                        onClick={() =>
                          copyToClipboard(detail?.FullyQualifiedDomainName)
                        }
                      >
                        <ContentCopyIcon fontSize='inherit' />
                      </IconButton>
                    </>
                  ) : (
                    '-'
                  )
                }
              />
            </Grid>
          </Grid>
        </>
      )}

      <Box mt={3} mb={3}>
        <Divider />
      </Box>

      <Grid container spacing={5}>
        <Grid item xs={6} sm={6} md={3}>
          <ListItemText
            primary='CreatedAt'
            secondary={
              detail?.CreatedAt
                ? formatDateFromISO(detail?.CreatedAt, inputFormatDateTime)
                : '-'
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <ListItemText
            primary='UpdatedAt'
            secondary={
              detail?.UpdatedAt
                ? formatDateFromISO(detail.UpdatedAt, inputFormatDateTime)
                : '-'
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(ContainerAppDescription);

ContainerAppDescription.propTypes = {
  detail: PropTypes.object,
};
