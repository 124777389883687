import { Box, Button, Grid, Stack, Tab, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppTooltip from '@tenant/core/AppTooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IntlMessages from '@tenant/utility/IntlMessages';
import { ROUTE_STRING } from 'constants/RouteString';
import { Formik } from 'formik';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import validationSchema from '../../redux/slices/appPackage/constants/validationSchema';
import { grey } from '@mui/material/colors';
import useAddAppPackage from 'redux/slices/appPackage/hooks/useAddAppPackage';
import DockerImageCollapseCard from './components/Add/DockerImageCollapseCard';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import AppPackageAddForm from './components/Common/AppPackageAddForm';

const Add = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState('1');
  const onClickBackButton = () => {
    navigate(ROUTE_STRING.APP_PACKAGE.LIST);
  };
  const addItem = useAddAppPackage();
  const [isValidAppContainer, setIsValidAppContainer] = useState(false);

  const handleSubmit = (data) => {
    if (!isValidAppContainer) {
      return;
    }

    addItem.mutate(
      {
        ...data,
        AppComponents: data?.AppComponents?.map((e) => ({
          Name: e.Name,
          ImageName: e.ImageName,
        })),
      },
      {
        onSuccess(result) {
          if (!result?.data?.Id) return;
          navigate(
            ROUTE_STRING.APP_PACKAGE.VIEW.replace(':id', result?.data?.Id),
          );
        },
      },
    );
  };

  const handleChangeIsValidAppContainer = (newValue) => {
    setIsValidAppContainer(newValue);
  };

  return (
    <>
      <AppsHeader>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              gap: 4,
            }}
            component='span'
            mr={{ xs: 2, sm: 4 }}
          >
            <AppTooltip title={<IntlMessages id='common.back' />}>
              <ArrowBackIcon
                sx={{
                  color: 'text.secondary',
                }}
                onClick={onClickBackButton}
              />
            </AppTooltip>
          </Box>
        </Box>
      </AppsHeader>

      <Box p={4}>
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{}}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ submitForm, values, setFieldValue, errors, validateField }) => (
            <AppPackageAddForm
              submitForm={submitForm}
              validateField={validateField}
              setForm={setFieldValue}
              handleChangeIsValidAppContainer={handleChangeIsValidAppContainer}
              onCancel={onClickBackButton}
              values={values}
              errors={errors}
              isValidAppContainer={isValidAppContainer}
              tab={tab}
              loading={addItem.isPending}
            />
          )}
        </Formik>
      </Box>
    </>
  );
};

export default Add;
