import React, { useState } from 'react';
import { getAvailableDockerImageQuery } from '../apis';
import { toast } from 'react-toastify';
import apiQuery from '@tenant/utility/helper/ApiHelper';

const useGetAvailableDockerImage = () => {
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [item, setItem] = useState();

  const refetch = (params) => {
    apiQuery({
      apiFnc: () => getAvailableDockerImageQuery(params),
      onStart() {
        setLoading(true);
      },
      onSuccess(data) {
        setItem(data);
        setIsLoaded(true);
      },
      onError(error) {
        const message = error?.response?.data?.message ?? 'Server error';
        toast.error(message);
        console.error(message + ':' + error);
      },
      onFinally() {
        setLoading(false);
      },
    });
  };

  return { refetch, item, loading, isLoaded };
};

export default useGetAvailableDockerImage;
