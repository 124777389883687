import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { addClientsByAppInstance } from '../apis';
import { addItem } from '..';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const useAddClientByAppInstance = () => {
  const dispatch = useDispatch();

  const addQuery = useMutation({
    mutationFn: addClientsByAppInstance,
    onSuccess: (data) => {
      toast.success('Add Idp Client successfully');
    },
    onError: (error) => {
      const message = error?.response?.data?.message ?? 'Server error';
      toast.error(message);
      console.error('Error add Idp Client package:', error);
    },
  });

  return addQuery;
};

export default useAddClientByAppInstance;
